import { useCallback } from 'react';

import { styles } from './styles';
import { IconImage } from '../Icon/renderIcon';
import { COLOR, TRANS } from '@constants';
import { PopoverTrigger } from '../Popover';
import { useDispatch } from 'react-redux';
import { ToastStatus } from '../Toast';
import { createUUID } from '@cyferd/client-engine';
import { uiActions } from '../../../client-app/state-mgmt/ui';

export interface ImageWithPopoverProps {
  alt: string;
  src: string;
  testid?: string;
}

export const ImageWithPopover = ({ src, alt, testid = 'image-with-popover' }: ImageWithPopoverProps) => {
  const dispatch = useDispatch();

  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(src);
    dispatch(uiActions.addToast({ id: createUUID(), status: ToastStatus.SUCCESS, title: `Image URL copied` }));
  }, [src, dispatch]);

  return (
    <div data-testid={`${testid}-container`} css={styles.button}>
      <PopoverTrigger
        optionList={[{ image: 'content_copy', onClick: copyToClipboard, important: true, testid: 'copy-btn', tooltip: TRANS.client.buttons.copyImage }]}
        value={
          <div css={styles.modal}>
            <img css={styles.image} src={src} alt={alt} loading="lazy" />
          </div>
        }
        testId={`${testid}-popover`}
      >
        <IconImage testid={testid} title={alt} imageProps={{ css: styles.image }} iconProps={{ size: '25px', fill: COLOR.BRAND_1 }} icon={src} />
      </PopoverTrigger>
    </div>
  );
};

ImageWithPopover.displayName = 'Image';
