import { useMemo } from 'react';
import { GeneralModel, useTranslate } from '@cyferd/client-engine';

import { useCurrentTheme } from '@components/providers/UIprovider';

import { FormulaInputType } from '../../getFormulaInputType';
import type { FormulaInputRow } from '../../resources';
import { defaultInputList, fxList } from '../../resources';

type OptionsMap = { [k: string]: FormulaInputRow };
type OptionsGroup = {
  groupName: string;
  groupIcon: GeneralModel.JSONSchemaFormat.ICON;
  isOpen: boolean;
  list: FormulaInputRow[];
};

export const useEvaluatorFormulas = ({ inputList }) => {
  const currentTheme = useCurrentTheme();
  const { translate } = useTranslate();

  return useMemo(() => {
    const groupMap: { [k: string]: OptionsGroup } = {};
    const formulaMap: OptionsMap = {};

    const addToMaps = (each: FormulaInputRow, groupOptions: any = {}) => {
      const formula: FormulaInputRow = {
        groupName: 'More',
        ...each,
        keywords: each.keywords?.map(e => translate(e)) || [],
        label: translate(each.label),
        description: translate(each.description)
      };

      formulaMap[each.id] = formula;
      if (!groupMap[formula.groupName]) {
        groupMap[formula.groupName] = {
          groupName: formula.groupName,
          ...groupOptions,
          list: []
        };
      }
      // eslint-disable-next-line no-restricted-syntax
      groupMap[each.groupName].list.push(formulaMap[each.id]);
    };

    defaultInputList.forEach(each => addToMaps(each, { isOpen: true }));
    inputList.forEach(each => addToMaps(each));
    GeneralModel.Color.colorNameList
      .filter(c => !GeneralModel.Color.baseThemeConfig[c]?.hidden)
      .forEach(color =>
        addToMaps({
          id: `Color-${currentTheme[color]?.label}`,
          label: currentTheme[color]?.label,
          formulaType: FormulaInputType.STRING,
          template: color,
          type: 'string',
          format: GeneralModel.JSONSchemaFormat.COLOR,
          output: { format: GeneralModel.JSONSchemaFormat.COLOR },
          groupName: 'Color',
          isAlias: true
        })
      );

    fxList.forEach(each => addToMaps(each, { groupIcon: 'function' }));

    return { groups: Object.values(groupMap), formulaMap };
  }, [currentTheme, inputList, translate]);
};
