import type { SchemaFormBaseProps } from '@cyferd/client-engine';
import { GeneralModel, getDisabledStates } from '@cyferd/client-engine';
import { createContext, useCallback } from 'react';
import { Draggable, Droppable } from '@hello-pangea/dnd';
import { styles } from '../styles';
import type { IOptionMenu } from '@components/elements/OptionMenu';
import { Icon } from '@components/elements/Icon';
import { FieldsetContent } from '@components/elements/Fieldset';
import { CTAType } from '@components/elements/CTA';

export interface ArrayItemContextValue {
  optionList: IOptionMenu['optionList'];
}

export const ArrayItemContext = createContext<ArrayItemContextValue>({
  optionList: []
});

export const useRenderArrayItem = ({
  getIds,
  maxColumns
}: {
  getIds: Function;
  maxColumns?: number;
}): SchemaFormBaseProps['componentRecord']['renderArrayItem'] =>
  useCallback(
    ({ id, item, index, removeButtonProps, disabled, disabledType, format }) => {
      const { isReadonly } = getDisabledStates(disabled, disabledType);
      return (
        <Droppable droppableId={`${id}-${index}`} type="array-item">
          {(droppableProvided, droppableSnapshot) => (
            <div {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
              <Draggable draggableId={`${id}-${index}`} index={index} isDragDisabled={removeButtonProps.disabled}>
                {(draggableProvided, draggableSnapshot) => (
                  <div {...draggableProvided.draggableProps} ref={draggableProvided.innerRef}>
                    {!!index && !draggableSnapshot.isDragging && <div css={styles.division} data-selector="division" />}
                    <div
                      {...getIds(id, { nativeHtmlElement: true })}
                      css={[
                        styles.arrayItemContainer(isReadonly),
                        !!disabled && /* istanbul ignore next */ !isReadonly && /* istanbul ignore next */ styles.containerDisabled,
                        !!droppableSnapshot.isDraggingOver && /* istanbul ignore next */ styles.isDraggingOver
                      ]}
                    >
                      {!isReadonly && (
                        <div {...draggableProvided.dragHandleProps} css={styles.dragIcon}>
                          <Icon name="drag_indicator" />
                        </div>
                      )}
                      <div css={styles.arrayItemStyles}>
                        <FieldsetContent testid="array-item" maxColumns={maxColumns}>
                          <ArrayItemContext.Provider
                            value={{
                              optionList: [
                                format !== GeneralModel.JSONSchemaFormat.FILE_LIST &&
                                  !isReadonly && {
                                    testid: removeButtonProps.id,
                                    disabled: removeButtonProps.disabled,
                                    onClick: removeButtonProps.onPress,
                                    image: 'delete',
                                    tooltip: 'Remove item',
                                    color: 'RD_4',
                                    type: CTAType.LINK,
                                    important: false
                                  }
                              ]
                            }}
                          >
                            {item}
                          </ArrayItemContext.Provider>
                        </FieldsetContent>
                      </div>
                    </div>
                  </div>
                )}
              </Draggable>
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      );
    },
    [getIds, maxColumns]
  );
