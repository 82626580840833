import { Draggable, Droppable } from '@hello-pangea/dnd';

import { prepareTermForReg, Translate } from '@cyferd/client-engine';

import { OPTION_PREFIX } from '../../resources';
import { Collapsible } from '../../../Collapsible';
import { PreventClickPropagation } from '../../../PreventClickPropagation';

import { FormulaOption } from '../../FormulaOption';
import { Icon } from '@components/elements/Icon';

export const EvaluatorInputList = ({ groups, styles, search }) => (
  <div css={[styles.toolbarList, styles.inputListContainer]}>
    {groups
      .map(({ list, groupName, groupIcon, isOpen }, index) => {
        const rows = list.filter(item => !item.isAlias && new RegExp(prepareTermForReg(search), 'i').test([...item.keywords, item.label, item.id].join(' ')));

        if (!rows.length) return null;

        return (
          <Collapsible
            key={`${groupName}-${index}`}
            open={isOpen || !!search}
            renderHeader={(onToggle, toggleButton, isOpen) => (
              <div data-testid="option-header" css={[styles.inputListHeader, !!isOpen && styles.inputListHeaderOpen]} onClick={onToggle}>
                <PreventClickPropagation>{toggleButton}</PreventClickPropagation>
                {groupIcon && <Icon name={groupIcon} />}
                <Translate>{groupName}</Translate>
              </div>
            )}
          >
            <div css={styles.inputListGroup}>
              {rows.map((item, i) => (
                <Droppable key={`${groupName}-${item.id}-${i}`} droppableId={`${OPTION_PREFIX}${item.id}`} isDropDisabled={true}>
                  {droppableProvided => (
                    <div {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
                      <Draggable draggableId={`${OPTION_PREFIX}${item.id}`} index={i}>
                        {draggableProvided => (
                          <div ref={draggableProvided.innerRef} {...draggableProvided.draggableProps} {...draggableProvided.dragHandleProps}>
                            <FormulaOption item={item} />
                          </div>
                        )}
                      </Draggable>
                      {droppableProvided.placeholder}
                    </div>
                  )}
                </Droppable>
              ))}
            </div>
          </Collapsible>
        );
      })
      .filter(Boolean)}
  </div>
);
