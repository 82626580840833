import type { CSSProperties } from 'react';
import React, { useContext, useMemo } from 'react';

import { GeneralModel } from '@cyferd/client-engine';

import { UIContext } from '@components/providers/UIprovider';
import { COLOR } from '@constants';
import { getEventPositionPercentage } from '@utils/getEventPositionPercentage';
import { styles } from './styles';
import { Cyferd } from './SVG/brand/Cyferd';
import { CyferdIcon } from './SVG/brand/CyferdIcon';
import { CyferdText } from './SVG/brand/CyferdText';
import { FileCheck } from './SVG/icons/FileCheck';
import { FileCross } from './SVG/icons/FileCross';
import { FileIcon } from './SVG/icons/FileIcon';
import { MoreVert } from './SVG/icons/MoreVert';
import { NG } from './SVG/icons/NG';
import { NG_color } from './SVG/icons/NG_color';
import { Success } from './SVG/icons/Success';
import { Layout_A } from './SVG/icons/Layout_A';
import { Layout_B } from './SVG/icons/Layout_B';
import { Layout_C } from './SVG/icons/Layout_C';
import { Layout_D } from './SVG/icons/Layout_D';
import { Layout_E } from './SVG/icons/Layout_E';
import { Layout_F } from './SVG/icons/Layout_F';
import { Layout_G } from './SVG/icons/Layout_G';
import { Layout_H } from './SVG/icons/Layout_H';
import { Layout_I } from './SVG/icons/Layout_I';
import { Layout_J } from './SVG/icons/Layout_J';
import { Layout_K } from './SVG/icons/Layout_K';
import { CyImageSize_S_1 } from './SVG/icons/CyImageSize_S_1';
import { CyImageSize_S_2 } from './SVG/icons/CyImageSize_S_2';
import { CyImageSize_S_3 } from './SVG/icons/CyImageSize_S_3';
import { CyImageSize_S_4 } from './SVG/icons/CyImageSize_S_4';
import { CyImageSize_S_5 } from './SVG/icons/CyImageSize_S_5';
import { Fx } from './SVG/icons/Fx';
import { CTA_PRIMARY } from './SVG/icons/CTA_PRIMARY';
import { CTA_SECONDARY } from './SVG/icons/CTA_SECONDARY';
import { CTA_TERTIARY } from './SVG/icons/CTA_TERTIARY';
import { CTA_ACTION } from './SVG/icons/CTA_ACTION';
import { CTA_ACTION_SECONDARY } from './SVG/icons/CTA_ACTION_SECONDARY';
import { CTA_ACTION_TERTIARY } from './SVG/icons/CTA_ACTION_TERTIARY';
import { FormatIcon } from './SVG/icons/FormatIcon';

export const svgComponentsList = {
  Cyferd,
  CyferdIcon,
  CyferdText,
  FileIcon,
  FileCross,
  FileCheck,
  FormatIcon,
  MoreVert,
  NG,
  NG_color,
  Success,
  Layout_A,
  Layout_B,
  Layout_C,
  Layout_D,
  Layout_E,
  Layout_F,
  Layout_G,
  Layout_H,
  Layout_I,
  Layout_J,
  Layout_K,
  CyImageSize_S_1,
  CyImageSize_S_2,
  CyImageSize_S_3,
  CyImageSize_S_4,
  CyImageSize_S_5,
  Fx,
  CTA_PRIMARY,
  CTA_SECONDARY,
  CTA_TERTIARY,
  CTA_ACTION,
  CTA_ACTION_SECONDARY,
  CTA_ACTION_TERTIARY
};

export type IconKeys = GeneralModel.IconName | keyof typeof svgComponentsList;

export interface IconProps {
  name: IconKeys;
  testid?: string;
  size?: `${number}px` | `${number}%` | `${number}em` | `${number}rem` | `var(--${string})` | 'fill';
  fill?: string;
  title?: string;
  percentage?: number;
  emptyColor?: string;
  outlined?: boolean;
  onClick?: (percentage: number) => void;
  // TODO: remove when clean up styling. At the moment needed for CyMap
  inlineStyle?: boolean;
}

export const Icon = ({
  name,
  testid: customDataTestid,
  title,
  size,
  fill,
  percentage = 1,
  emptyColor = COLOR.NEUTRAL_4,
  outlined,
  onClick,
  inlineStyle = false
}: IconProps) => {
  const { runtimeTheme } = useContext(UIContext);

  const testid = customDataTestid || 'icon';
  const interactive = typeof onClick === 'function';

  const safeName = useMemo(() => (GeneralModel.iconConfigMap[name] ? name : 'trip_origin') as GeneralModel.IconName, [name]);

  const variantClassName = outlined ? 'notranslate material-symbols-outlined' : 'notranslate material-symbols-rounded';

  const SvgComponent = svgComponentsList[name];
  if (!!SvgComponent) {
    const svgSizeProp = size === 'fill' || !size ? '100%' : size;
    return <SvgComponent size={svgSizeProp} fill={fill} title={title} data-testid={testid} theme={runtimeTheme} />;
  }

  const onInternalClick = (event: React.MouseEvent) => {
    /* istanbul ignore else */
    if (interactive) onClick(getEventPositionPercentage(event));
  };

  // TODO: remove when styles are properly done with classes
  const styleIcon = inlineStyle
    ? {
        style: {
          display: 'inline-block',
          position: 'relative',
          color: fill || COLOR.BASE_FOREGROUND,
          fontSize: size || '1em',
          cursor: interactive ? 'pointer' : undefined
        } as CSSProperties
      }
    : {
        css: styles.icon({ fill, interactive, size })
      };

  if (percentage !== 1) {
    return (
      <span css={styles.container} onClick={onInternalClick} data-testid={testid}>
        <span css={styles.partialIcon} style={{ width: `${percentage * 100}%` }}>
          <span {...styleIcon} className={variantClassName} title={title}>
            {safeName}
          </span>
        </span>
        <span css={styles.icon({ interactive, size, fill: emptyColor })} className={variantClassName} title={title}>
          {safeName}
        </span>
      </span>
    );
  }

  return (
    <span onClick={onInternalClick} {...styleIcon} className={variantClassName} data-testid={testid} title={title}>
      {safeName}
    </span>
  );
};

Icon.displayName = 'Icon';
