import { forwardRef, useMemo, useState } from 'react';
import type { Observable } from 'rxjs';

import type { ApiModel, GeneralModel } from '@cyferd/client-engine';
import { prepareTermForReg } from '@cyferd/client-engine';

import { DropdownOption } from '@components/elements/DropdownOption';
import { SearchInput } from '@components/elements/SearchInput';
import { CTA, CTAType } from '@components/elements/CTA';
import { IconImage } from '@components/elements/Icon/renderIcon';
import { Checkbox } from '@components/elements/Checkbox';
import { COLOR } from '@constants';
import type { ChipOption } from '@components/elements/Chips/ChipDropdown';
import { PreventClickPropagation } from '@components/elements/PreventClickPropagation';
import { getStyles } from './styles';

const renderIcon = (iconName: string, title: string | number) => (
  <IconImage
    icon={iconName}
    title={String(title)}
    iconProps={{ size: '20px', fill: COLOR.NEUTRAL_1 }}
    imageProps={{ css: { width: 20, height: 20, objectFit: 'cover', borderRadius: '100%' } }}
  />
);

export interface ICheckboxMenu {
  testid?: string;
  optionList: (GeneralModel.InputOption | ChipOption)[];
  onSelect: (value: GeneralModel.InputOption & { onClick?: () => void | Observable<ApiModel.APIAction> }) => void;
  onSelectAll?: () => void;
  selectedOptions?: (string | number)[];
  hideSearch?: boolean;
  defaultCheckboxColor?: GeneralModel.Color.ThemeColor;
}

export const CheckboxMenu = forwardRef<HTMLDivElement, ICheckboxMenu>(
  (
    { testid = 'checkbox-menu', optionList = [], onSelect, onSelectAll, selectedOptions, hideSearch = false, defaultCheckboxColor = 'BRAND_1' }: ICheckboxMenu,
    ref
  ) => {
    const styles = useMemo(() => getStyles({ hideSearch }), [hideSearch]);
    const [search, setSearch] = useState('');
    const filteredList = useMemo(() => {
      if (search) {
        const regex = new RegExp(prepareTermForReg(search), 'i');
        return optionList.filter(({ value, label }) => regex.test(String(value)) || regex.test(String(label)));
      }
      return optionList;
    }, [optionList, search]);

    return (
      <div data-testid={testid} css={styles.listContainer} ref={ref}>
        {!hideSearch && (
          <div css={styles.search}>
            <SearchInput searchDelay={0} autoFocus={true} value={search} onChange={setSearch} />
          </div>
        )}
        <div css={[styles.options, !hideSearch && styles.borderTop]}>
          {!!onSelectAll && !!filteredList && (
            <div css={[styles.item, styles.notHover]}>
              <div style={{ marginLeft: 'auto' }}>
                <CTA
                  disabled={filteredList.length === selectedOptions?.length}
                  onClick={onSelectAll}
                  color={defaultCheckboxColor}
                  type={CTAType.LINK}
                  icon={{ name: 'add_box', position: 'end' }}
                  testid="select-all"
                >
                  Select all
                </CTA>
              </div>
            </div>
          )}
          {filteredList?.map((opt, index) => {
            const { value, image, label, color } = opt;
            const selected = selectedOptions?.includes(value);
            return (
              <div key={`${value}-${index}`}>
                <PreventClickPropagation containerCss={styles.preventClick}>
                  <div
                    data-testid={`${testid}-item-${index}`}
                    onClick={() => onSelect(opt)}
                    css={[styles.item, selected && styles.itemSelected, !!COLOR[color] && styles.itemWithColor]}
                  >
                    <div css={styles.colorBar} style={{ backgroundColor: COLOR[color] }}></div>
                    <Checkbox
                      id={String(value)}
                      color={(selected ? color || /* istanbul ignore next */ defaultCheckboxColor : 'NEUTRAL_3') as GeneralModel.Color.ThemeColor}
                      testid={`${testid}-${index}-checkbox`}
                      value={selectedOptions?.includes(value)}
                    />
                    {!!image && renderIcon(image, value)}
                    {!!label && <div>{label}</div>}
                  </div>
                </PreventClickPropagation>
              </div>
            );
          })}
        </div>
        {!filteredList.length && <DropdownOption title="No results found" testid={`${testid}-empty`} />}
      </div>
    );
  }
);
