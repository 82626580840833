import { COLOR, DROPDOWN_BASE_STYLES, FONT_SIZE, FONT_WEIGHT, GAP, INPUT_HEIGHT, RADIUS, TRANSITION_SPEED } from '@constants';
import { css } from '@emotion/react';

export const getStyles = ({ hideSearch }: { hideSearch: boolean }) => ({
  listContainer: css`
    ${DROPDOWN_BASE_STYLES};
    background-color: ${COLOR.TOP_BACKGROUND};
    padding: ${hideSearch ? GAP.XS : GAP.M} ${GAP.S} ${GAP.XXXS};
    max-height: 372px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  `,
  search: css`
    form {
      background: transparent;
      border: none;
    }
  `,
  options: css`
    gap: ${GAP.S};
    display: flex;
    flex-direction: column;
    padding: ${GAP.S} 0px;
    overflow: auto;
  `,
  borderTop: css`
    border-top: 1px solid ${COLOR.NEUTRAL_3};
  `,
  item: css`
    display: flex;
    align-items: center;
    position: relative;
    justify-content: flex-start;
    gap: ${GAP.S};
    overflow: hidden;
    border-radius: ${RADIUS.S};
    min-height: calc(${INPUT_HEIGHT} - 2px);
    padding: ${GAP.XXS} ${GAP.S};
    transition: all ${TRANSITION_SPEED};
    border-radius: ${RADIUS.XS};
    background-color: ${COLOR.TRANSPARENT};
    cursor: pointer;
    font-size: ${FONT_SIZE.S};
    font-weight: ${FONT_WEIGHT.NORMAL};
    color: ${COLOR.NEUTRAL_1};

    :hover {
      background-color: ${COLOR.NEUTRAL_6};
    }
  `,
  itemSelected: css`
    background-color: ${COLOR.NEUTRAL_5};
    color: ${COLOR.BASE_FOREGROUND};
  `,
  itemWithColor: css`
    border-radius: ${GAP.XXS} ${RADIUS.S} ${RADIUS.S} ${GAP.XXS};
  `,
  notHover: css`
    cursor: auto;
    :hover {
      background-color: transparent;
    }
  `,
  colorBar: css`
    width: ${RADIUS.S};
    border-radius: ${RADIUS.S};
    top: 0;
    bottom: 0;
    left: 0;
    position: absolute;
  `,
  preventClick: css`
    width: 100%;
  `
});
