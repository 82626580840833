import { create } from 'zustand';
import { onlyOneIsTrue } from '@utils';

interface PopoverState {
  popoverIds: Record<string, boolean>;
  openPopover: (popoverId: string) => void;
  closePopover: () => void;
  optionMenu: {
    optionMenuIds: Record<string, boolean>;
    openOptionMenu: (optionMenuId: string) => void;
    closeOptionMenu: () => void;
  };
}

export const usePopover = create<PopoverState>(set => ({
  popoverIds: {},
  openPopover: (popoverId: string) => set(state => ({ ...state, popoverIds: onlyOneIsTrue(popoverId, state.popoverIds) })),
  closePopover: () => set(state => ({ ...state, popoverIds: onlyOneIsTrue(null, state.popoverIds) })),
  optionMenu: {
    optionMenuIds: {},
    openOptionMenu: (optionMenuId: string) =>
      set(state => ({
        optionMenu: {
          ...state.optionMenu,
          optionMenuIds: onlyOneIsTrue(optionMenuId, state.optionMenu.optionMenuIds)
        }
      })),
    closeOptionMenu: () =>
      set(state => ({
        optionMenu: {
          ...state.optionMenu,
          optionMenuIds: onlyOneIsTrue(null, state.optionMenu.optionMenuIds)
        }
      }))
  }
}));
