/* istanbul ignore file */
import { useState } from 'react';
import { ApiModel, ErrorBoundary, GeneralModel, ViewModel, isObject, noop, normalize, useTranslate } from '@cyferd/client-engine';

import { Layout } from '@components/elements/Layout';
import { RichText } from '@components/elements/RichTextEditor';
import { COLOR } from '@constants';
import { styles } from '../styles';
import { getPrettyTitlePath } from '../utils';
import { SchemaForm } from '../../../../builder/views/shared/SchemaForm';

export interface ApiRefActionsProps {
  type: string;
}

export const ApiRefActions = ({ type }: ApiRefActionsProps) => {
  const [activeTab, setActiveTab] = useState('Input');
  const config = ApiModel.FlowSchemaMap[type];
  const hasInput = isObject(config?.input?.schema?.properties);
  const hasOutput = isObject(config?.output?.schema?.properties);
  const hasExamples = !!config?.examples?.length;
  return (
    <Layout type={ViewModel.LayoutType.FULL}>
      <RichText value={config?.description} />
      <Layout
        type={ViewModel.LayoutType.TAB}
        tabList={[hasInput && { title: 'Input' }, hasOutput && { title: 'Output' }, hasExamples && { title: 'Examples' }].filter(Boolean)}
        activeTab={activeTab}
        onChangeTab={setActiveTab as any}
        fitToPage={true}
      >
        {hasInput && (
          <div>
            <ErrorBoundary>
              <Props properties={normalize.schema(config.input?.schema)?.properties} />
            </ErrorBoundary>
          </div>
        )}
        {hasOutput && (
          <div>
            <ErrorBoundary>
              <Props properties={normalize.schema(config.output?.schema)?.properties} />
            </ErrorBoundary>
          </div>
        )}
        {hasExamples && (
          <div>
            <ErrorBoundary>
              <SchemaForm
                value={config}
                schema={examplesSchema}
                maxColumns={1}
                onChange={noop}
                disabled={true}
                disabledType={GeneralModel.DisabledType.VIEW_ONLY}
              />
            </ErrorBoundary>
          </div>
        )}
      </Layout>
    </Layout>
  );
};

const Props = ({ properties, labelPath = [] }: { properties: Record<string, GeneralModel.JSONSchema>; labelPath?: string[] }) => {
  const { translate } = useTranslate();
  return Object.values(properties).map((prop, i) => {
    const newLabelPath = [...labelPath, prop.label].map(l => l?.trim?.()).filter(Boolean);

    return (
      <div key={`${prop.$id}-${i}`}>
        {!!i && <div css={styles.division} />}
        {!!prop.key && (
          <div css={styles.compoPropsTable}>
            <RichText
              value={`
  <h5>${prop.metadata?.hidden === true ? '⚠ UNSTABLE ' : ''}${getPrettyTitlePath(newLabelPath)}</h5>
  <p><strong style="color: ${COLOR.HC_2}">key:</strong> ${prop.key}</p>
  <p><strong style="color: ${COLOR.HC_2}">type:</strong> ${translate(prop.type)}</p>
  ${['string', 'number', 'boolean'].includes(typeof prop.default) ? `<p><strong style="color: ${COLOR.HC_2}">default:</strong> ${prop.default}</p>` : ''}
  ${
    !prop.metadata?.optionList?.length
      ? ''
      : `<p><strong style="color: ${COLOR.HC_2}">options:</strong></p>
  <ul>${prop.metadata.optionList.map(o => `<li><strong>${translate(o.label)}: </strong> ${translate(o.value as string)}</li>`).join('')}</ul>
  `
  }
  `}
            />
            <RichText
              value={`
  ${prop.description ? `<p>${translate(prop.description)}</p><p><br/></p>` : ''}
  ${prop.info ? `<p>${translate(prop.info)}</p>` : ''}
  `}
            />
          </div>
        )}
        {prop.type === 'object' && !!Object.keys(prop.properties || {}).length && (
          <>
            {!!prop.key && <div css={styles.division} />}
            <Props properties={prop.properties} labelPath={newLabelPath} />
          </>
        )}
        {prop.type === 'array' && !!Object.keys(prop.items?.properties || {}).length && (
          <>
            {!!prop.key && <div css={styles.division} />}
            <Props properties={prop.items.properties} labelPath={newLabelPath} />
          </>
        )}
      </div>
    );
  });
};

const getPropSearchContent = (prop: GeneralModel.JSONSchema): string[] => {
  if (!isObject(prop)) return [];
  const normalizedProp = normalize.schema(prop);
  return [
    normalizedProp.key,
    normalizedProp.label,
    normalizedProp.description,
    normalizedProp.info,
    normalizedProp.metadata?.optionList?.map?.(o => `${o.label}: ${o.value} ${o.description || ''}`),
    ...Object.values(normalizedProp.properties || {})
      .map(getPropSearchContent)
      .flat(),
    ...getPropSearchContent(normalizedProp.items)
  ].flat();
};

export const getActionSearchContent = (type: string) => {
  const config = ApiModel.FlowSchemaMap[type];
  return [config.name, config.id, config.description, ...Object.values(config?.input?.schema?.properties || {}).map(getPropSearchContent)]
    .flat()
    .filter(Boolean)
    .join('<hr/>');
};

const examplesSchema = normalize.schema(
  {
    type: 'object',
    properties: {
      examples: {
        type: 'array',
        label: ' ',
        metadata: { unlimitedHeight: true, isCollapsible: false },
        items: {
          type: 'object',
          label: 'Example',
          properties: {
            name: { type: 'string', label: 'Name' },
            description: { type: 'string', label: 'Description', format: GeneralModel.JSONSchemaFormat.MULTILINE },
            input: {
              type: 'object',
              label: 'Input',
              format: GeneralModel.JSONSchemaFormat.JSON,
              metadata: { expanded: true, fieldSize: GeneralModel.FieldSize.FULL }
            },
            output: {
              type: 'object',
              label: 'Output',
              format: GeneralModel.JSONSchemaFormat.JSON,
              metadata: { expanded: true, fieldSize: GeneralModel.FieldSize.FULL }
            }
          }
        }
      }
    }
  },
  { avoidAlphabeticalSort: true }
);
