import { css } from '@emotion/react';
import { GAP } from '@constants';
import { CHIP_HEIGHT } from '@components/elements/Chips/Chip';

export const styles = {
  chipList: css`
    display: flex;
    gap: ${GAP.S} ${GAP.XS};
    flex-wrap: wrap;
    align-items: center;
  `,
  chipListLimited: css`
    max-height: ${CHIP_HEIGHT * 2.6}px;
    overflow: auto;
  `,
  collapsed: css`
    max-height: ${CHIP_HEIGHT + 1}px;
    overflow: auto;
  `
};
