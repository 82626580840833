import type { ComponentProps } from 'react';
import React, { memo, useCallback, useContext, useMemo, useState } from 'react';

import type { GeneralModel } from '@cyferd/client-engine';
import { ViewModel, noop } from '@cyferd/client-engine';
import MonacoEditor from '@monaco-editor/react';

import { FileTextInput } from '@components/elements/FileTextInput';
import { COLOR, FONT_SIZE, THEME } from '@constants';
import { styles } from './styles';
import type { IOptionListItem, IOptionMenu } from '../OptionMenu';
import { InputWrapper } from '../InputWrapper';
import { Modal } from '../Modal';
import { CTA, CTAType } from '../CTA';
import { UIContext } from '@components/providers/UIprovider';
import { PreventClickPropagation } from '../PreventClickPropagation';
import { Icon } from '../Icon';

export interface SyntaxEditorProps extends Partial<ComponentProps<typeof MonacoEditor>> {
  language:
    | GeneralModel.JSONSchemaFormat.SQL
    | GeneralModel.JSONSchemaFormat.GRAPHQL
    | GeneralModel.JSONSchemaFormat.XML
    | GeneralModel.JSONSchemaFormat.YAML
    | GeneralModel.JSONSchemaFormat.JSON
    | string;
  value: string;
  disabled?: boolean;
  onChange: (newValue: string) => void;
  testid?: string;
  label: string;
  color?: GeneralModel.Color.ThemeColor;
  description?: string;
  required?: boolean;
  errorMessage?: string;
  allowFileInput?: boolean;
  optionList?: IOptionMenu['optionList'];
  expanded?: boolean;
  info?: string;
  disabledType?: GeneralModel.DisabledType;
  avoidExpandOption?: boolean;
}

export const SyntaxEditor = memo(
  ({
    value,
    onChange,
    disabled = false,
    testid,
    label,
    description,
    language,
    required = false,
    errorMessage,
    allowFileInput,
    optionList,
    color,
    expanded,
    info,
    disabledType,
    avoidExpandOption,
    ...props
  }: SyntaxEditorProps) => {
    const [open, setOpen] = useState<boolean>(false);
    const internalTestid = testid || `${language}-editor`;

    const onInternalChange = useCallback((event: string) => onChange(event === '' ? null : event), [onChange]);
    const { runtimeTheme } = useContext(UIContext);

    const onToggle = useCallback(() => setOpen(p => !p), []);

    const onClear = useCallback(() => onChange?.(undefined), [onChange]);

    const internalOptionList = useMemo(
      () =>
        [
          ...(optionList || []),
          !avoidExpandOption && {
            tooltip: 'Full screen',
            image: 'open_in_full',
            onClick: onToggle,
            type: CTAType.LINK,
            important: !optionList?.length,
            size: ViewModel.CTASize.MEDIUM
          }
        ].filter(Boolean) as IOptionListItem[],
      [avoidExpandOption, onToggle, optionList]
    );

    const stringValue = useMemo(
      () =>
        String(!expanded && !open && typeof value === 'string' ? value : value ? JSON.stringify(value) : '')
          .replace(/\n/g, '')
          .replace(/\s\s/g, ''),
      [expanded, open, value]
    );

    const modal = !!open && (
      <Modal
        open={true}
        title={label}
        description={description}
        type={ViewModel.ModalType.FULL_SCREEN}
        onClose={onToggle}
        footer={<CTA label="OK" type={CTAType.PRIMARY} onClick={onToggle} />}
      >
        <SyntaxEditor
          value={value}
          onChange={onChange}
          disabled={disabled}
          testid={testid}
          label={label}
          description={description}
          language={language}
          required={required}
          errorMessage={errorMessage}
          allowFileInput={allowFileInput}
          optionList={optionList}
          color={color}
          {...props}
          expanded={true}
          avoidExpandOption={true}
          height="calc(90vh - 200px)"
        />
      </Modal>
    );

    if (!expanded) {
      return (
        <>
          <div onClick={onToggle} data-testid={`${internalTestid}-container`}>
            <InputWrapper
              color={color}
              label={label}
              onChange={noop}
              disabled={!!disabled}
              optionList={optionList}
              description={description}
              errorMessage={errorMessage}
              info={info}
              required={required}
              disabledType={disabledType}
            >
              <div css={styles.container}>
                <MonacoEditor
                  css={disabled && styles.disabledEditor}
                  height={20}
                  data-testid={`${internalTestid}-preview`}
                  onChange={noop}
                  theme={/* istanbul ignore next */ runtimeTheme === THEME.DARK ? 'hc-black' : 'vs'}
                  value={stringValue}
                  language={language}
                  options={{
                    minimap: { enabled: false },
                    lineNumbers: 'off',
                    lineDecorationsWidth: 0,
                    lineNumbersMinChars: 0,
                    fontSize: 11,
                    readOnly: true
                  }}
                />
                {![undefined, ''].includes(value) && !disabled && (
                  <PreventClickPropagation>
                    <div data-selector="clear">
                      <CTA testid="clear-btn" type={CTAType.SEEMLESS} onClick={onClear}>
                        <Icon name="close" fill={COLOR.NEUTRAL_2} size={FONT_SIZE.XM} />
                      </CTA>
                    </div>
                  </PreventClickPropagation>
                )}
              </div>
            </InputWrapper>
          </div>
          {modal}
        </>
      );
    }

    return (
      <>
        <div css={styles.mainContainer}>
          <InputWrapper
            isContainerInput={false}
            unlimitedHeight={true}
            disabled={!!disabled}
            id={testid}
            testid={testid}
            label={label}
            description={description}
            errorMessage={errorMessage}
            optionList={internalOptionList}
            color={color}
            info={info}
            required={required}
            disabledType={disabledType}
          >
            <div css={styles.expandedContainer} data-testid={`${internalTestid}-container`}>
              <MonacoEditor
                css={disabled && styles.disabledEditor}
                height={200}
                data-testid={`${internalTestid}-input`}
                onChange={onInternalChange}
                theme={/* istanbul ignore next */ runtimeTheme === THEME.DARK ? 'hc-black' : 'vs'}
                value={String([null, undefined].includes(value) ? '' : value)}
                language={language}
                options={{ minimap: { enabled: false }, formatOnPaste: true, formatOnType: false, fontSize: 11, readOnly: disabled }}
                {...props}
                path={undefined}
              />
              {!!allowFileInput && <FileTextInput disabled={disabled} onChange={onChange} testid={`${internalTestid}-fileInput`} />}
            </div>
          </InputWrapper>
        </div>
        {modal}
      </>
    );
  }
);

SyntaxEditor.displayName = 'SyntaxEditor';
