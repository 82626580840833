import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface BannerState {
  bannerHeight: number;
  startBannerHeightListener: () => () => void;
}

export const useBannerStore = create<BannerState>()(
  devtools(
    set => ({
      bannerHeight: undefined,
      startBannerHeightListener: () => {
        const handleResize = () => {
          const banner = document.querySelector('[data-testid="banner"]');
          if (banner) {
            set({ bannerHeight: banner.clientHeight });
          }
        };

        const observer = new ResizeObserver(handleResize);
        const banner = document.querySelector('[data-testid="banner"]');
        if (banner) {
          observer.observe(banner);
        }

        return () => {
          if (banner) observer.unobserve(banner);
        };
      }
    }),
    {
      name: 'banner-store'
    }
  )
);
