export const onlyOneIsTrue = (id: string | null, record: Record<string, boolean>): Record<string, boolean> => {
  const updatedRecord: Record<string, boolean> = {};

  for (const key of Object.keys(record)) {
    updatedRecord[key] = false;
  }

  if (id !== null) {
    updatedRecord[id] = true;
  }

  return updatedRecord;
};
