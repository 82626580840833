import { Icon } from '@components/elements/Icon';
import { styles } from '../styles';
import { DropdownOption } from '@components/elements/DropdownOption';
import type { InsertType } from '../types';
import { INSERT_HORIZONTAL_RULE_COMMAND } from '@lexical/react/LexicalHorizontalRuleNode';
import { INSERT_TABLE_COMMAND } from '@lexical/table';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useContext, useRef } from 'react';
import { $createParagraphNode, $getSelection } from 'lexical';
import { $createFileNode } from '../../../nodes/FileNode';
import { useFileManagement } from '../../../hooks/useFileManagement';
import { ClientEngineContext, createUUID, tapOnSuccess, useUnmountObservable } from '@cyferd/client-engine';
import { catchError, EMPTY, finalize, takeUntil } from 'rxjs';
import { logger } from '@utils';
import { useDispatch } from 'react-redux';
import { uiActions } from '../../../../../../../client-app/state-mgmt/ui';
import { ToastStatus } from '@components/elements/Toast';
import type { FileObject } from '../../../types';
import { ToolbarDropdown } from './ToolbarDropdown';
import { styleHelpers } from '@utils/styleHelpers';
import { GAP } from '@constants';
import { css } from '@emotion/react';
import { $createExtendedTextNode } from '../../../nodes/ExtendedTextNode';
import { formatInfoBlock } from '@components/elements/RichTextEditor/RichEditorComposition/components/Toolbar/utils';

export const InsertOptionsSelector = ({ isSmallViewport }) => {
  const [editor] = useLexicalComposerContext();

  const dispatch = useDispatch();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const { platformConfig } = useContext(ClientEngineContext);
  const { onUploadFile } = useFileManagement();
  const onDestroy$ = useUnmountObservable();

  const insertFile = (file: FileObject) => {
    editor.update(() => {
      const selection = $getSelection();

      if (selection !== null) {
        const fileNode = $createFileNode(file, `${platformConfig?.fileUploadUrl}/${file?.id}`);
        const paragraphNode = $createParagraphNode();

        const spaceNode = $createExtendedTextNode(' ');

        selection.insertNodes([fileNode, spaceNode]);
        fileNode.getParent().insertAfter(paragraphNode);

        paragraphNode.select();
      }
    });
  };

  const onChangeFileInput = (file: File) => {
    dispatch(uiActions.addToast({ id: createUUID(), status: ToastStatus.INFO, title: 'The file upload was initiated. Please wait.' }));

    onUploadFile(file)
      .pipe(
        takeUntil(onDestroy$),
        tapOnSuccess(apiFileData => {
          dispatch(uiActions.addToast({ id: createUUID(), status: ToastStatus.SUCCESS, title: 'The file was uploaded successfully.' }));

          insertFile(apiFileData as FileObject);
          logger.debug('Success uploading file', apiFileData);
        }),
        catchError(error => {
          dispatch(uiActions.addToast({ id: createUUID(), status: ToastStatus.ERROR, title: 'Something wrong happened. The file cannot be uploaded.' }));

          logger.error('Error uploading file', { error });
          return EMPTY;
        }),
        finalize(() => fileInputRef?.current?.setAttribute('value', undefined))
      )
      .subscribe();
  };

  const handleInsert = (type: InsertType) => {
    if (type === 'divider') {
      editor.dispatchCommand(INSERT_HORIZONTAL_RULE_COMMAND, undefined);
    } else if (type === 'file') {
      fileInputRef.current.click();
    } else if (type === 'table') {
      editor.dispatchCommand(INSERT_TABLE_COMMAND, {
        columns: '3',
        rows: '2'
      });
    } else if (type === 'info') {
      formatInfoBlock(editor, 'info');
    }
  };

  return (
    <>
      <ToolbarDropdown
        trigger={
          <div css={styleHelpers.flexStart(isSmallViewport ? GAP.XXXS : GAP.XS)}>
            <Icon name="add" size="24px" />

            {!isSmallViewport && (
              <p
                css={css`
                  font-weight: 400;
                `}
              >
                Insert
              </p>
            )}

            <Icon name="arrow_drop_down" />
          </div>
        }
        options={insertOptions(isSmallViewport).map(option => (
          <DropdownOption key={option.value} image={option.icon} title={option.label} value={option.value} onClick={handleInsert} imageOutlined={true} />
        ))}
      />

      <input
        ref={fileInputRef}
        multiple={false}
        autoComplete="new-password"
        type="file"
        css={styles.fileHiddenInput}
        onChange={event => onChangeFileInput(event.target.files[0])}
        disabled={false}
        value=""
      />
    </>
  );
};

const insertOptions = isSmallViewport => {
  const options = [
    { value: 'file', label: 'File', icon: 'article' },
    { value: 'table', label: 'Table', icon: 'table' },
    { value: 'divider', label: 'Divider', icon: 'remove' }
  ];

  if (isSmallViewport) {
    return [{ value: 'info', label: 'Info block', icon: 'info' }, ...options];
  }

  return options;
};
