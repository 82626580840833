import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import type { DragStart, DropResult } from '@hello-pangea/dnd';
import { DragDropContext } from '@hello-pangea/dnd';

import type { GeneralModel } from '@cyferd/client-engine';
import { ErrorBoundary, replaceDeep } from '@cyferd/client-engine';

import type { FormulaInputRow } from '../resources';
import {
  EVALUATOR_TAB,
  OPTION_PREFIX,
  conditionalDotReplacement,
  escapeKeys,
  getHandlingEmptyPath,
  getStateAfterDrop,
  getStateAfterNew,
  scrollToElement
} from '../resources';
import { getStyles } from './styles';
import { useTestingHelper } from '@utils';
import { JSONSyntaxEditor } from '../../JSONSyntaxEditor';
import { EvaluatorTree } from '../EvaluatorTree';
import { EvaluatorKeyChangeModal } from '../EvaluatorKeyChangeModal';
import { TabList } from '@components/elements/TabList';
import { FormulaTester } from '../FormulaTester';
import { SearchInput } from '@components/elements/SearchInput';
import { EvaluatorInputList } from './components/EvaluatorInputList';
import { useEvaluatorFormulas } from './hooks/useEvaluatorFormulas';

export interface EvaluatorUIProps {
  value: any;
  showAdvanced: boolean;
  disabled: boolean;
  inputList: FormulaInputRow[];
  height: string;
  hideTabs?: boolean;
  onChange: (arg: GeneralModel.EvaluatorFormula) => void;
}

export const EvaluatorUI = memo(({ value, showAdvanced, disabled, inputList, height, hideTabs, onChange }: EvaluatorUIProps) => {
  const { getTestIdProps } = useTestingHelper('evaluator-input');
  const [search, setSearch] = useState<string>('');
  const [activePath, setActivePath] = useState<string>();
  const [pathBeingDragged, setPathBeingDragged] = useState<string>();
  const [keyChangePath, setKeyChangePath] = useState<string>();
  const [tab, setTab] = useState<EVALUATOR_TAB>(EVALUATOR_TAB.INPUTS);
  const styles = useMemo(() => getStyles(height, tab), [height, tab]);

  const escapedValue = useMemo(() => escapeKeys(value), [value]);

  const { groups, formulaMap } = useEvaluatorFormulas({ inputList });

  const onChangeActive = useCallback((event: string) => setActivePath(p => (p === event ? null : event)), []);

  /* istanbul ignore next line */
  const onBeforeDragStart = useCallback((event: DragStart) => {
    setPathBeingDragged(event?.draggableId);
  }, []);

  /* istanbul ignore next line */
  const onOptionDragEnd = useCallback(
    (result: DropResult) => {
      const draggableId = result.draggableId.replace(new RegExp(`^${OPTION_PREFIX}`), '');
      const option = formulaMap[draggableId];
      const newState = getStateAfterNew({
        state: value,
        result,
        option,
        label: option?.label
      });

      setActivePath(null);
      onChange(newState);
      setPathBeingDragged(null);
    },
    [formulaMap, onChange, value]
  );

  /* istanbul ignore next line */
  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result?.destination?.droppableId) return;
      if (result?.draggableId?.startsWith?.(OPTION_PREFIX)) return onOptionDragEnd(result);
      setActivePath(null);
      onChange(getStateAfterDrop(value, result));
      setPathBeingDragged(null);
    },
    [onChange, onOptionDragEnd, value]
  );

  const onKeyChangeCancel = useCallback(() => setKeyChangePath(null), []);

  const onRenameKey = useCallback(
    (event: string) => {
      const parentPath = conditionalDotReplacement(keyChangePath.substring(0, keyChangePath.lastIndexOf('.')), 'unescape');
      const oldKey = conditionalDotReplacement(keyChangePath.substring?.(keyChangePath.lastIndexOf('.') + 1, keyChangePath.length), 'unescape');
      const parent$ = getHandlingEmptyPath(value, parentPath);
      const changed = Object.fromEntries(Object.entries(parent$).map(([k, v]) => [k === oldKey ? conditionalDotReplacement(event, 'unescape') : k, v]));
      onChange(replaceDeep(value, changed, parentPath));
      onKeyChangeCancel();
      setActivePath(null);
      const newLocation = [parentPath, event].join('.');
      scrollToElement(newLocation);
    },
    [keyChangePath, onChange, onKeyChangeCancel, value]
  );

  useEffect(() => {
    if (activePath) scrollToElement(activePath);
  }, [activePath]);

  return (
    <>
      {!!keyChangePath && <EvaluatorKeyChangeModal path={keyChangePath} onCancel={onKeyChangeCancel} onApply={onRenameKey} />}
      <DragDropContext onDragEnd={onDragEnd} onBeforeDragStart={onBeforeDragStart}>
        <div css={styles.editorContainer}>
          <div css={styles.sidebarContainer}>
            <div>
              {!hideTabs && (
                <TabList
                  tabList={[
                    { title: EVALUATOR_TAB.INPUTS, displayName: 'Fx & items' },
                    { title: EVALUATOR_TAB.PLAYGROUND, displayName: 'Test' }
                  ]}
                  activeTab={tab}
                  onChangeTab={setTab as any}
                />
              )}
            </div>
            {tab === EVALUATOR_TAB.PLAYGROUND && (
              <ErrorBoundary>
                <FormulaTester formula={value} height={`calc(${height} - 65px)`} />
              </ErrorBoundary>
            )}
            {tab === EVALUATOR_TAB.INPUTS && (
              <div css={styles.toolbarContainer}>
                <SearchInput value={search} onChange={setSearch} alternative={true} />
                <EvaluatorInputList groups={groups} styles={styles} search={search} />
              </div>
            )}
          </div>
          <div css={styles.toolbarContainer}>
            {showAdvanced ? (
              <JSONSyntaxEditor
                expanded={true}
                avoidExpandOption={true}
                label=""
                disabled={disabled}
                onChange={onChange}
                value={value}
                height={`calc(${height} - 30px)`}
              />
            ) : (
              <div css={styles.toolbarList} {...getTestIdProps('formula-ui')}>
                <ErrorBoundary>
                  <EvaluatorTree
                    value={escapedValue}
                    inputList={inputList}
                    activePath={activePath}
                    pathBeingDragged={pathBeingDragged}
                    height={height}
                    readonly={disabled}
                    onChange={onChange}
                    onChangeActive={onChangeActive}
                    onKeyChange={setKeyChangePath}
                  />
                </ErrorBoundary>
              </div>
            )}
          </div>
        </div>
      </DragDropContext>
    </>
  );
});
