import { css } from '@emotion/react';
import { COLOR, GAP, RADIUS } from '@constants';

export const styles = {
  wrapper: bgColor => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${GAP.M};
    border-radius: ${RADIUS.S};
    padding: ${GAP.XS};
    background-color: ${bgColor};
    margin: ${GAP.M} 0;
    min-height: 38px;
    width: 100%;
    text-align: left;
  `,
  divider: bgColor => css`
    width: 4px;
    background-color: ${bgColor};
    align-self: stretch;
    min-height: 32px;
    border-radius: ${RADIUS.S};
  `,
  floatingMenu: css`
    padding: ${GAP.XS} ${GAP.M};
    border-radius: ${RADIUS.S};
    border: 1px solid ${COLOR.NEUTRAL_3};
    background-color: ${COLOR.BASE_BACKGROUND};
    gap: ${GAP.M};
  `
};
