import { css } from '@emotion/react';
import { COLOR, GAP, RADIUS, SHADOW, Z_INDEX } from '@constants';

export const styles = {
  linkEditor: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: ${Z_INDEX.DROPDOWN};
    max-width: 350px;
    min-width: 300px;
    background-color: ${COLOR.TOP_BACKGROUND};
    box-shadow: ${SHADOW.S};
    border-radius: ${RADIUS.S};
    gap: ${GAP.M};
    padding: ${GAP.XS};
  `
};
