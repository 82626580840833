import { DropdownMenu } from '@components/elements/DropdownMenu';
import { styleHelpers } from '@utils/styleHelpers';
import { createPortal } from 'react-dom';
import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import { COLOR, DROPDOWN_BASE_STYLES, GAP, RADIUS, Z_INDEX } from '@constants';
import type { ReactNode } from 'react';

interface ToolbarDropdownProps {
  trigger: ReactNode;
  options: ReactNode;
  optionsCss?: SerializedStyles;
}

export const ToolbarDropdown = ({ trigger, options, optionsCss }: ToolbarDropdownProps) => {
  return (
    <DropdownMenu
      horizontalResponsive={false}
      styling={styleHelpers.flexCenter}
      renderTrigger={({ onClick, ref }) => (
        <div ref={ref} onClick={onClick} css={[styleHelpers.flexBetween, styleHelpers.cursorPointer]}>
          {trigger}
        </div>
      )}
      renderMenuWrapper={({ css, onClick, children, style, ref }) =>
        createPortal(
          <div css={styles.menuWrapper} data-portal="true">
            <div style={style} css={css} onClick={onClick} ref={ref}>
              {children}
            </div>
          </div>,
          document.body
        )
      }
    >
      <div css={[styles.optionsWrapper, optionsCss]}>{options}</div>
    </DropdownMenu>
  );
};

const styles = {
  menuWrapper: css`
    position: absolute;
    z-index: ${Z_INDEX.DROPDOWN};
  `,
  optionsWrapper: css`
    ${DROPDOWN_BASE_STYLES};
    background-color: ${COLOR.BASE_BACKGROUND};
    z-index: ${Z_INDEX.DROPDOWN};
    padding: ${GAP.S};
    border-radius: ${RADIUS.S};
    max-height: 270px;
    overflow: auto;
    max-width: 190px;
  `
};
