import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect } from 'react';
import type { ListNode } from '@lexical/list';
import { $isListItemNode, $isListNode, ListItemNode } from '@lexical/list';
import type { LexicalNode } from 'lexical';

export const ListTransformPlugin = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerNodeTransform(ListItemNode, node => {
      const parent = node.getParent() as ListNode;
      const listType = parent.getListType();

      const nestedLists = node.getChildren().filter(child => $isListNode(child));
      const isSeveralLists = nestedLists.length > 1;

      if (isSeveralLists) {
        mergeLists(nestedLists[0], nestedLists[1], listType);
      }
    });
  }, [editor]);

  return null;
};

const isNestedListNode = (node: LexicalNode | null | undefined) => $isListItemNode(node) && $isListNode(node.getFirstChild());

const mergeLists = (list1: ListNode, list2: ListNode, type): void => {
  list1.setListType(type);
  list2.setListType(type);

  const listItem1 = list1.getLastChild() as ListItemNode;
  const listItem2 = list2.getFirstChild() as ListItemNode;

  if (listItem1 && listItem2 && isNestedListNode(listItem1) && isNestedListNode(listItem2)) {
    mergeLists(listItem1.getFirstChild(), listItem2.getFirstChild(), type);
    listItem2.remove();
  }

  const toMerge = list2.getChildren();
  if (toMerge.length > 0) {
    list1.append(...toMerge);
  }

  list2.remove();
};
