import type { ComponentProps } from 'react';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import type { Observable } from 'rxjs';
import { EMPTY, Subject, catchError, of, takeUntil, zip } from 'rxjs';
import { useDispatch } from 'react-redux';

import type { ApiModel, FormComponentRecord, GeneralModel } from '@cyferd/client-engine';
import { createUUID, getDisabledStates, removeKeyList, tapOnSuccess, useFinalizeWhileMounted, useLogger, useUnmountObservable } from '@cyferd/client-engine';

import { APP_LOGGER_HEADER } from '@constants';
import { getLabel } from '@utils';
import { styles } from './styles';
import { EditInformationModal } from '../EditInformationModal';
import { Dropzone, DropzoneFileStatus } from '../Dropzone';
import type { IOptionMenu } from '../OptionMenu';
import { FileViewerModal } from '../FileViewerModal';
import { InputWrapper } from '../InputWrapper';
import { ToastStatus } from '../Toast';
import { uiActions } from '../../../client-app/state-mgmt/ui';

// TODO: investigate and refactor that type. It requires the  types which should not be required.
export type FileInputProps = Omit<Parameters<FormComponentRecord['renderDefault']>[0], 'getOptionList'> & {
  multiple?: boolean;
  onGetFileRequest?: (file: File) => Observable<any>;
  onDownloadFile?: (fileId: string) => Observable<ApiModel.APIAction>;
  preferredOptionList?: string[];
  optionList?: IOptionMenu['optionList'];
  allowedFileTypes?: string[];
  info?: string;
  errorMessage?: string;
  onlyDropzone?: boolean;
  disabledType?: GeneralModel.DisabledType;
  testid?: string;
  onRemoveCallback?: () => void;
};

export const FileInput = ({
  id,
  displayNamePath,
  description,
  disabled,
  value,
  multiple,
  onChange,
  onGetFileRequest,
  onDownloadFile,
  optionList,
  schema,
  allowedFileTypes,
  info,
  required,
  color,
  errorMessage,
  onlyDropzone,
  disabledType,
  testid = 'file-input',
  onRemoveCallback
}: FileInputProps) => {
  const dispatch = useDispatch();
  const ref = useRef<HTMLInputElement>();
  const onDestroy$ = useUnmountObservable();
  const [status, setStatus] = useState<DropzoneFileStatus>(value?.uploadedAt ? DropzoneFileStatus.SUCCESS : null);
  const [tempValue, setTempValue] = useState<ComponentProps<typeof Dropzone>['value']>(null);
  const cancelRef = useRef(new Subject<void>());
  const logger = useLogger(APP_LOGGER_HEADER);
  const finalize = useFinalizeWhileMounted();
  const [showFile, setShowFile] = useState<boolean>(false);
  const [editingFile, setEditingFile] = useState<boolean>(false);
  const belongsToList = schema?.metadata?.isList;

  const { isReadonly, isGreyedOut } = getDisabledStates(disabled, disabledType);
  const isUploading = useMemo(() => [DropzoneFileStatus.LOADING].includes(tempValue && tempValue[0]?.status), [tempValue]);
  const errorOnUpload = useMemo(() => [DropzoneFileStatus.ERROR].includes(tempValue && tempValue[0]?.status), [tempValue]);

  const dropzoneValue = [
    {
      id: value?.id,
      status: status || value?.uploadedAt || value?.status || value?.id ? DropzoneFileStatus.SUCCESS : undefined,
      metadata: removeKeyList(value || {}, ['id', 'name']),
      title: value?.name,
      mimeType: value?.mimeType,
      uploadedAt: value?.uploadedAt
    }
  ].filter(f => !!f?.id) as ComponentProps<typeof Dropzone>['value'];

  const onToggleShowFile = useCallback(() => setShowFile(p => !p), []);
  const onToggleEditFile = useCallback(() => setEditingFile(p => !p), []);

  const onInternalChange = (files: File[]) => {
    if (!files?.length) return;

    setTempValue(files.map(file => ({ id: `temp-id-${createUUID()}`, status: DropzoneFileStatus.LOADING, title: file.name, mimeType: file.type })));
    (multiple
      ? zip(
          files.map(file =>
            onGetFileRequest(file).pipe(
              catchError(error => {
                logger.error('Error uploading file', { error });
                dispatch(uiActions.addToast({ id: createUUID(), status: ToastStatus.ERROR, title: `Upload failed: ${file.name}` }));
                return of(undefined);
              })
            )
          )
        )
      : onGetFileRequest(files[0])
    )
      .pipe(
        takeUntil(onDestroy$),
        takeUntil(cancelRef.current),
        tapOnSuccess(apiFileData => {
          setStatus(DropzoneFileStatus.SUCCESS);
          setTempValue(null);
          const newVal = Array.isArray(apiFileData) ? apiFileData.filter(Boolean).map(file => ({ ...file, status: DropzoneFileStatus.SUCCESS })) : apiFileData;
          onChange(newVal);
          logger.debug('Success uploading file', { apiFileData: newVal });
        }),
        catchError(error => {
          logger.error('Error uploading file', { error });
          setTempValue(p => [{ ...(p || /* istanbul ignore next | JIC */ [])[0], status: DropzoneFileStatus.ERROR }]);
          return EMPTY;
        }),
        finalize(() => ref?.current?.setAttribute('value', undefined))
      )
      .subscribe();
  };

  const onDownload = useCallback(
    (internalId?: string) => onDownloadFile((typeof internalId === 'string' && /* istanbul ignore next */ internalId) || value.id),
    [value, onDownloadFile]
  );

  const onRemove = useCallback(() => {
    onChange(undefined);
    onRemoveCallback?.();
  }, [onChange, onRemoveCallback]);
  const onReplace = () => ref?.current?.click();
  const onCancel = () => {
    cancelRef.current.next();
    setTempValue(null);
  };
  const onEditInformation = (thisValue: any) => {
    onChange({ ...value, ...thisValue });
    setEditingFile(false);
  };

  const safeOptionList = useMemo(
    () =>
      [
        ...(isUploading || errorOnUpload
          ? [{ testid: 'cancel-upload', onClick: onCancel, image: 'close', tooltip: 'cancel', color: 'NEUTRAL_2', important: true }]
          : !Array.isArray(tempValue) && [
              !!value?.id && { image: 'web', testid: `${testid}-preview`, tooltip: 'Preview', onClick: onToggleShowFile },
              !!value?.id && !!onDownloadFile && { image: 'download', testid: `${testid}-download`, tooltip: 'Download', onClick: onDownload },
              !!value && !isReadonly && { image: 'upload', testid: `${testid}-replace`, tooltip: 'Replace', onClick: onReplace, disabled },
              !!value && !isReadonly && schema && { image: 'edit', testid: `${testid}-edit`, tooltip: 'Edit information', onClick: onToggleEditFile, disabled },
              !tempValue &&
                !!value &&
                !isReadonly && {
                  image: 'close',
                  testid: `${testid}-delete`,
                  tooltip: 'Remove file',
                  color: 'NEUTRAL_2',
                  onClick: onRemove,
                  disabled,
                  important: true
                }
            ]),
        ...(optionList || [])
      ].filter(Boolean) as IOptionMenu['optionList'],
    [
      disabled,
      errorOnUpload,
      isReadonly,
      isUploading,
      onDownload,
      onRemove,
      onToggleEditFile,
      onToggleShowFile,
      optionList,
      tempValue,
      testid,
      value,
      onDownloadFile,
      schema
    ]
  );

  const dropzone = (
    <Dropzone
      multiple={multiple}
      ref={ref}
      testid={testid}
      disabled={disabled}
      value={tempValue || dropzoneValue}
      onChange={onInternalChange}
      onPreview={!!value?.uploadedAt ? onToggleShowFile : undefined}
      allowedFileTypes={allowedFileTypes}
      readOnly={isReadonly}
      disableDragAndDrop={!!value && belongsToList}
    />
  );

  /* istanbul ignore next line */
  if (onlyDropzone) return dropzone;

  return (
    <>
      <div
        data-selector="file-input"
        css={[
          styles.container,
          !value && !errorOnUpload && styles.containerWithoutValue(isReadonly),
          !value && !disabled && styles.containerHover,
          isUploading && styles.containerUploading,
          /* istanbul ignore next line */
          isGreyedOut && styles.containerDisabled,
          /* istanbul ignore next line */
          errorOnUpload && styles.containerError
        ]}
      >
        <InputWrapper
          id={id}
          label={getLabel(displayNamePath)}
          description={description}
          disabled={!!disabled}
          disabledType={disabledType}
          showPlaceholderLine={true}
          unframed={isReadonly}
          value={value}
          onChange={onChange}
          optionList={safeOptionList}
          info={info}
          errorMessage={errorMessage}
          required={required}
          color={color}
          testid={testid}
        >
          {dropzone}
        </InputWrapper>
      </div>
      {/* this elements must be outside the mainContainer to avoid css styles overriding */}
      {!!editingFile && <EditInformationModal value={value} onClose={onToggleEditFile} onEdit={onEditInformation} disabled={disabled} schema={schema} />}
      {!!showFile && <FileViewerModal title={getLabel(displayNamePath)} value={value} onClose={onToggleShowFile} onDownload={onDownload} />}
    </>
  );
};
