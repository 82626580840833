import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { ApiModel } from '@cyferd/client-engine';
import type { GeneralModel } from '@models';

import { getInitialState } from './state';
import type { UIState } from './state';
import type { ToastProps } from '@components/elements/Toast';
import type { NotificationModel } from '@cyferd/client-engine';

const uiSlice = createSlice({
  name: 'ui',
  initialState: getInitialState(),
  reducers: {
    setLoading: (state: UIState, action: PayloadAction<string[]>) => {
      state.loadingList = action.payload;
    },
    addLoading: (state: UIState, action: PayloadAction<string>) => {
      if (!state.loadingList.includes(action.payload)) {
        state.loadingList.push(action.payload);
      }
    },
    removeLoading: (state: UIState, action: PayloadAction<string>) => {
      state.loadingList = state.loadingList.filter(id => id !== action.payload);
    },
    addToast: (state: UIState, action: PayloadAction<{ toast: Partial<ToastProps>; content: any }>) => {
      state.toastList.push(action.payload);
    },
    removeToast: (state: UIState, action: PayloadAction<string>) => {
      state.toastList = state.toastList.filter(({ toast }) => toast.id !== action.payload);
    },
    addMessage: (state: UIState, action: PayloadAction<Partial<NotificationModel.Notification>>) => {
      state.messageList.push(action.payload);
    },
    removeMessage: (state: UIState, action: PayloadAction<string>) => {
      state.messageList = state.messageList.filter(({ id }) => id !== action.payload);
    }
  },
  extraReducers: builder => {
    builder
      .addCase(ApiModel.TriggerActionType.DISPATCH_SET_VIEW, (state: UIState) => {
        state.activeModalList = []; // Reset modal list
      })
      .addCase(ApiModel.TriggerActionType.DISPATCH_TOGGLE_MODAL, (state: UIState, action: GeneralModel.Action) => {
        const { action: modalAction, modalId } = action.payload as ApiModel.TriggerPayloadModel.DISPATCH_TOGGLE_MODAL;
        switch (modalAction) {
          case 'open':
            state.activeModalList = [modalId];
            break;
          case 'close':
            state.activeModalList = [];
            break;
          case 'toggle':
          default:
            state.activeModalList = state.activeModalList.includes(modalId) ? [] : [modalId];
        }
      });
  }
});

export const uiActions = {
  ...uiSlice.actions,
  addToast: (toast: Partial<ToastProps>, content?: any) => uiSlice.actions.addToast({ toast, content })
};

export const uiReducer = uiSlice.reducer;

export type UiActions = typeof uiActions;
export type UiState = ReturnType<typeof getInitialState>;
