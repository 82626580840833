import { useCallback, useEffect, useRef, useState } from 'react';
import {
  $getSelection,
  $isLineBreakNode,
  $isRangeSelection,
  $setSelection,
  CLICK_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_LOW,
  KEY_ESCAPE_COMMAND,
  SELECTION_CHANGE_COMMAND
} from 'lexical';
import { getSelectedNode } from '../../utils/positioning';
import { $findMatchingParent, mergeRegister } from '@lexical/utils';
import { $createLinkNode, $isAutoLinkNode, $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { styles } from './styles';
import { styleHelpers } from '@utils/styleHelpers';
import { Input } from '@components/elements/Input';
import { GeneralModel } from '@cyferd/client-engine';
import { ToolbarButton } from '../../components/ToolbarButton';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { flip, size, useDismiss, useFloating, useInteractions } from '@floating-ui/react';

export const FloatingLinkEditorPlugin = ({ isFocused }) => {
  const [editor] = useLexicalComposerContext();

  const inputRef = useRef<HTMLInputElement>(null);

  const [linkUrl, setLinkUrl] = useState('https://');
  const [editedLinkUrl, setEditedLinkUrl] = useState('https://');
  const [isEditorOpen, setIsEditorOpen] = useState(false);

  const { x, y, strategy, refs, update, context } = useFloating({
    placement: 'bottom-start',
    middleware: [
      flip(),
      size({
        apply({ availableWidth, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            maxWidth: `${availableWidth}px`,
            maxHeight: `${availableHeight}px`
          });
        }
      })
    ],
    open: isEditorOpen,
    onOpenChange: setIsEditorOpen
  });
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  useEffect(() => {
    const $updateToolbar = () => {
      if (!isFocused) {
        setIsEditorOpen(false);
        return;
      }

      const selection = $getSelection();

      if ($isRangeSelection(selection)) {
        const focusNode = getSelectedNode(selection);

        const focusLinkNode = $findMatchingParent(focusNode, $isLinkNode);
        const focusAutoLinkNode = $findMatchingParent(focusNode, $isAutoLinkNode);

        if (!(focusLinkNode || focusAutoLinkNode)) {
          setIsEditorOpen(false);
          return;
        }
        const badNode = selection
          .getNodes()
          .filter(node => !$isLineBreakNode(node))
          .find(node => {
            const linkNode = $findMatchingParent(node, $isLinkNode);
            const autoLinkNode = $findMatchingParent(node, $isAutoLinkNode);
            return (
              (focusLinkNode && !focusLinkNode.is(linkNode)) ||
              (linkNode && !linkNode.is(focusLinkNode)) ||
              (focusAutoLinkNode && !focusAutoLinkNode.is(autoLinkNode)) ||
              (autoLinkNode && (!autoLinkNode.is(focusAutoLinkNode) || autoLinkNode.getIsUnlinked()))
            );
          });

        if (!badNode) {
          const linkElement = editor.getElementByKey(focusLinkNode?.getKey()) || editor.getElementByKey(focusAutoLinkNode?.getKey());
          refs.setReference(linkElement);
          update();

          setIsEditorOpen(true);
        } else {
          setIsEditorOpen(false);
        }
      }
    };

    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        _payload => {
          $updateToolbar();
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      ),
      editor.registerCommand(
        CLICK_COMMAND,
        _payload => {
          $updateToolbar();
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      )
    );
  }, [editor, refs, update, isFocused]);

  const $updateLinkEditor = useCallback(() => {
    const selection = $getSelection();

    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection);
      const linkParent = $findMatchingParent(node, $isLinkNode);

      if (linkParent) {
        setLinkUrl(linkParent.getURL());
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL());
      } else {
        setLinkUrl('');
      }
      setEditedLinkUrl(linkUrl);
    }

    return true;
  }, [linkUrl, setEditedLinkUrl]);

  useEffect(() => {
    editor.getEditorState().read(() => {
      $updateLinkEditor();
    });
  }, [editor, $updateLinkEditor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateLinkEditor();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          $updateLinkEditor();
          return true;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        KEY_ESCAPE_COMMAND,
        () => {
          if (isEditorOpen) {
            setIsEditorOpen(false);
            return true;
          }
          return false;
        },
        COMMAND_PRIORITY_HIGH
      )
    );
  }, [editor, $updateLinkEditor, setIsEditorOpen, isEditorOpen]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditorOpen]);

  const monitorInputInteraction = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleLinkSubmission();
    }
  };

  const handleLinkSubmission = () => {
    if (linkUrl !== '') {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, editedLinkUrl);
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          const parent = getSelectedNode(selection).getParent();
          if ($isAutoLinkNode(parent)) {
            const linkNode = $createLinkNode(parent.getURL(), {
              rel: parent.__rel,
              target: parent.__target,
              title: parent.__title
            });
            parent.replace(linkNode, true);
          }
        }
      });
    }
    setEditedLinkUrl('https://');
    editor.update(() => $setSelection(null));
    setIsEditorOpen(false);
  };

  if (!isEditorOpen) return null;

  return (
    <div
      css={styles.linkEditor}
      {...getFloatingProps()}
      {...getReferenceProps()}
      ref={refs.setFloating}
      style={{
        position: strategy,
        top: y ?? 0,
        left: x ?? 0
      }}
    >
      <div css={styleHelpers.flex1}>
        <Input
          ref={inputRef}
          value={editedLinkUrl}
          onChange={value => setEditedLinkUrl(value)}
          type={GeneralModel.JSONSchemaFormat.URL}
          noWrapperPadding={true}
          onKeyDown={monitorInputInteraction}
        />
      </div>

      <div css={styleHelpers.flexBetween}>
        <ToolbarButton onClick={handleLinkSubmission} iconName="check" active={false} />
      </div>

      <ToolbarButton onClick={() => editor.dispatchCommand(TOGGLE_LINK_COMMAND, null)} iconName="close" active={false} />
    </div>
  );
};
