import React, { useRef } from 'react';

import { readFileAsText } from '@utils';
import { CTA, CTAType } from '../CTA';
import { styles } from './styles';

export type Props = {
  testid?: string;
  onChange: (value: string) => void;
  disabled?: boolean;
};

const accept = ['text/*', 'application/json', '.config', '.json', '.ppk'].join(', ');

export const FileTextInput = ({ testid = 'FileTextInput', onChange, disabled }: Props) => {
  const inputRef = useRef(null);

  const onInternalChange = async (file: File) => {
    try {
      onChange(String(await readFileAsText(file)));
    } catch (e) {
      console.error(e);
    }
  };

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const [file] = Array.from(event.dataTransfer.files);
    onInternalChange(file);
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const [file] = event.target.files;
    onInternalChange(file);
  };

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const onClick = () => {
    inputRef.current.click();
  };

  return (
    <div onDrop={onDrop} onDragOver={onDragOver} data-testid={testid}>
      <label css={styles.label} htmlFor="files" data-testid={`${testid}-label`}>
        <CTA
          disabled={disabled}
          type={CTAType.LINK}
          icon="note_add"
          label="Insert file"
          tooltip="Select a file to fill this field"
          onClick={onClick}
          testid={`${testid}-cta`}
        />
      </label>
      <input ref={inputRef} id="files" type="file" css={styles.input} onChange={onInputChange} data-testid={`${testid}-input`} accept={accept} />
    </div>
  );
};
