import type { ComponentProps } from 'react';

import type { Adder } from '@components/elements/Adder';
import { colorPerType } from '@components/elements/Evaluator/getFormulaInputType';
import { TRANS, fileTypeFormatList, getDataTypeIcon, getDataTypeLabel } from '@constants';
/* istanbul ignore file | these are configurations */
import { ApiModel, GeneralModel, createUUID, mergeTruthy } from '@cyferd/client-engine';

import { creatorDetailGroupMap, schemaFieldMap } from './schemas';

export const templateNameList = [
  GeneralModel.JSONSchemaFormat.TEXT,
  GeneralModel.JSONSchemaFormat.STRING_OPTION_LIST,
  GeneralModel.JSONSchemaFormat.INLINE_STRING_OPTION_LIST,
  GeneralModel.JSONSchemaFormat.COLOR,
  GeneralModel.JSONSchemaFormat.ICON_IMAGE,
  GeneralModel.JSONSchemaFormat.DATE,
  GeneralModel.JSONSchemaFormat.DATE_TIME_U,
  GeneralModel.JSONSchemaFormat.EMAIL,
  GeneralModel.JSONSchemaFormat.MONTH,
  GeneralModel.JSONSchemaFormat.WEEK,
  GeneralModel.JSONSchemaFormat.TIME,
  GeneralModel.JSONSchemaFormat.PASSWORD,
  GeneralModel.JSONSchemaFormat.URL,
  GeneralModel.JSONSchemaFormat.QR,
  GeneralModel.JSONSchemaFormat.BARCODE,
  GeneralModel.JSONSchemaFormat.PHONE_NUMBER,
  GeneralModel.JSONSchemaFormat.MULTILINE,
  GeneralModel.JSONSchemaFormat.RICH_TEXT,
  GeneralModel.JSONSchemaFormat.SQL,
  GeneralModel.JSONSchemaFormat.GRAPHQL,
  GeneralModel.JSONSchemaFormat.YAML,
  GeneralModel.JSONSchemaFormat.XML,
  GeneralModel.JSONSchemaFormat.COLLECTION_LOOKUP,
  GeneralModel.JSONSchemaFormat.COLLECTION_FIELD,
  GeneralModel.JSONSchemaFormat.NUMBER,
  GeneralModel.JSONSchemaFormat.CURRENCY,
  GeneralModel.JSONSchemaFormat.NUMBER_OPTION_LIST,
  GeneralModel.JSONSchemaFormat.RANGE,
  GeneralModel.JSONSchemaFormat.NUMERIC_RANGE,
  GeneralModel.JSONSchemaFormat.RATING,
  GeneralModel.JSONSchemaFormat.CHECKBOX,
  GeneralModel.JSONSchemaFormat.SWITCH,
  GeneralModel.JSONSchemaFormat.OBJECT,
  GeneralModel.JSONSchemaFormat.FILE,
  GeneralModel.JSONSchemaFormat.ARRAY,
  GeneralModel.JSONSchemaFormat.FILE_LIST,
  GeneralModel.JSONSchemaFormat.MULTI_OPTION_LIST,
  GeneralModel.JSONSchemaFormat.MULTI_OPTION_LIST_ALT,
  GeneralModel.JSONSchemaFormat.JSON,
  GeneralModel.JSONSchemaFormat.ASSOCIATION,
  GeneralModel.JSONSchemaFormat.ADDRESS,
  GeneralModel.JSONSchemaFormat.COLLECTION_FILTER,
  GeneralModel.JSONSchemaFormat.COLLECTION_RECORD,
  GeneralModel.JSONSchemaFormat.INFO_BLOCK
] as const;

export type TemplateName = (typeof templateNameList)[number];

const {
  hidden,
  allowFileInput,
  booleanLabelFalse,
  booleanLabelTrue,
  calculation,
  color,
  disabled,
  icon,
  invalid,
  mask,
  maxItems,
  maxLength,
  maximum,
  metadata,
  minItems,
  minLength,
  minimum,
  multipleOf,
  optionList,
  pattern,
  secondaryColor,
  shouldCleanHidden,
  subtype,
  allowedFileTypes,
  uniqueItems,
  fieldSize,
  ctaConfig,
  startsCollapsed,
  expanded,
  suggestionList,
  unlimitedHeight,
  disabledType,
  isCollapsible
} = schemaFieldMap;

export const getTypeDefaultProps = (type: GeneralModel.JSONSchema['type'], format: GeneralModel.JSONSchema['format']): GeneralModel.JSONSchema => {
  switch (type) {
    case 'string':
      return {};
    case 'number':
    case 'integer':
      if (format === GeneralModel.JSONSchemaFormat.RATING) {
        return {
          maximum: 10,
          metadata: { icon: 'star', color: 'OE_6', secondaryColor: 'NEUTRAL_4' }
        };
      }
      if (format === GeneralModel.JSONSchemaFormat.RANGE) {
        return {
          minimum: 0,
          maximum: 100,
          default: 0,
          multipleOf: 1,
          metadata: { subtype: GeneralModel.JSONSchemaSubtype.CONTINUOUS }
        };
      }
      return {};
    case 'boolean':
      return { default: false };
    case 'object':
      if (format === GeneralModel.JSONSchemaFormat.ADDRESS) {
        return {
          properties: {
            road: {
              $id: createUUID(),
              type: 'string',
              label: 'Road',
              format: GeneralModel.JSONSchemaFormat.TEXT,
              metadata: { detailOrder: 1 }
            },
            houseNumber: {
              $id: createUUID(),
              type: 'string',
              label: 'House number',
              format: GeneralModel.JSONSchemaFormat.TEXT,
              metadata: { detailOrder: 2 }
            },
            city: {
              $id: createUUID(),
              type: 'string',
              label: 'City',
              format: GeneralModel.JSONSchemaFormat.TEXT,
              metadata: { detailOrder: 3 }
            },
            postcode: {
              $id: createUUID(),
              type: 'string',
              label: 'Postcode',
              format: GeneralModel.JSONSchemaFormat.TEXT,
              metadata: { detailOrder: 4 }
            },
            state: {
              $id: createUUID(),
              type: 'string',
              label: 'State',
              format: GeneralModel.JSONSchemaFormat.TEXT,
              metadata: { detailOrder: 5 }
            },
            country: {
              $id: createUUID(),
              type: 'string',
              label: 'Country',
              format: GeneralModel.JSONSchemaFormat.STRING_OPTION_LIST,
              metadata: {
                detailOrder: 6,
                optionList: GeneralModel.countryList.map(c => ({ value: c.name }))
              }
            },
            lat: {
              $id: createUUID(),
              type: 'number',
              label: 'Latitude',
              format: GeneralModel.JSONSchemaFormat.NUMBER,
              metadata: { detailOrder: 7 }
            },
            lng: {
              $id: createUUID(),
              type: 'number',
              label: 'Longitude',
              format: GeneralModel.JSONSchemaFormat.NUMBER,
              metadata: { detailOrder: 8 }
            },
            county: {
              $id: createUUID(),
              type: 'string',
              label: 'County',
              format: GeneralModel.JSONSchemaFormat.TEXT,
              metadata: { hidden: true }
            },
            category: {
              $id: createUUID(),
              type: 'string',
              label: 'Category',
              format: GeneralModel.JSONSchemaFormat.TEXT,
              metadata: { hidden: true }
            },
            continent: {
              $id: createUUID(),
              type: 'string',
              label: 'Continent',
              format: GeneralModel.JSONSchemaFormat.TEXT,
              metadata: { hidden: true }
            },
            countryCode: {
              $id: createUUID(),
              type: 'string',
              label: 'Country code',
              format: GeneralModel.JSONSchemaFormat.TEXT,
              metadata: { hidden: true }
            },
            stateCode: {
              $id: createUUID(),
              type: 'string',
              label: 'State code',
              format: GeneralModel.JSONSchemaFormat.TEXT,
              metadata: { hidden: true }
            },
            stateDistrict: {
              $id: createUUID(),
              type: 'string',
              label: 'State district',
              format: GeneralModel.JSONSchemaFormat.TEXT,
              metadata: { hidden: true }
            },
            suburb: {
              $id: createUUID(),
              type: 'string',
              label: 'Suburb',
              format: GeneralModel.JSONSchemaFormat.TEXT,
              metadata: { hidden: true }
            }
          },
          required: [],
          additionalProperties: true
        };
      }
      if (format === GeneralModel.JSONSchemaFormat.FILE)
        return {
          properties: {
            id: {
              $id: createUUID(),
              type: 'string',
              label: 'ID',
              format: GeneralModel.JSONSchemaFormat.TEXT,
              metadata: { hidden: true }
            },
            name: { $id: createUUID(), type: 'string', label: 'Name', format: GeneralModel.JSONSchemaFormat.TEXT },
            encoding: {
              $id: createUUID(),
              type: 'string',
              label: 'Encoding',
              format: GeneralModel.JSONSchemaFormat.TEXT,
              metadata: { hidden: true }
            },
            mimeType: {
              $id: createUUID(),
              type: 'string',
              label: 'Mime type',
              format: GeneralModel.JSONSchemaFormat.TEXT,
              metadata: { hidden: true }
            },
            uploadedAt: {
              $id: createUUID(),
              type: 'string',
              label: 'Uploaded at',
              format: GeneralModel.JSONSchemaFormat.TEXT,
              metadata: { hidden: true }
            },
            uploadedBy: {
              $id: createUUID(),
              type: 'string',
              label: 'Uploaded by',
              format: GeneralModel.JSONSchemaFormat.TEXT,
              metadata: { hidden: true }
            }
          },
          required: [],
          additionalProperties: true
        };
      return { properties: {}, required: [], additionalProperties: true };
    case 'array':
      if (format === GeneralModel.JSONSchemaFormat.FILE_LIST) {
        return {
          items: mergeTruthy(getTypeDefaultProps('object', GeneralModel.JSONSchemaFormat.FILE), {
            $id: createUUID(),
            type: 'object',
            format: GeneralModel.JSONSchemaFormat.FILE,
            metadata: {}
          }),
          required: [],
          additionalProperties: true
        };
      }

      if ([GeneralModel.JSONSchemaFormat.MULTI_OPTION_LIST, GeneralModel.JSONSchemaFormat.MULTI_OPTION_LIST_ALT].includes(format)) {
        return {
          uniqueItems: true,
          items: mergeTruthy(getTypeDefaultProps('string', GeneralModel.JSONSchemaFormat.STRING_OPTION_LIST), {
            $id: createUUID(),
            type: 'string',
            format: GeneralModel.JSONSchemaFormat.STRING_OPTION_LIST,
            metadata: { optionList: [] }
          }),
          required: []
        };
      }

      if (format === GeneralModel.JSONSchemaFormat.NUMERIC_RANGE) {
        return {
          minimum: 0,
          maximum: 100,
          default: [0, 100],
          multipleOf: 1,
          metadata: {
            subtype: GeneralModel.JSONSchemaSubtype.CONTINUOUS
          },
          items: mergeTruthy(getTypeDefaultProps('number', GeneralModel.JSONSchemaFormat.NUMBER), {
            $id: createUUID(),
            type: 'number',
            format: GeneralModel.JSONSchemaFormat.NUMBER,
            metadata: {}
          }),
          required: []
        };
      }

      return {
        minItems: 0,
        uniqueItems: false,
        items: mergeTruthy(getTypeDefaultProps('string', GeneralModel.JSONSchemaFormat.TEXT), {
          $id: createUUID(),
          type: 'string',
          format: GeneralModel.JSONSchemaFormat.TEXT,
          metadata: {}
        })
      };
    default:
      return {};
  }
};

export const templateGroupConfig: { title: string; list: TemplateName[] }[] = (() => {
  const groupList = [
    {
      title: TRANS.client.dataTypes.categories.text,
      list: [
        GeneralModel.JSONSchemaFormat.TEXT,
        GeneralModel.JSONSchemaFormat.MULTILINE,
        GeneralModel.JSONSchemaFormat.RICH_TEXT,
        GeneralModel.JSONSchemaFormat.PASSWORD
      ]
    },
    {
      title: TRANS.client.dataTypes.categories.number,
      list: [
        GeneralModel.JSONSchemaFormat.NUMBER,
        GeneralModel.JSONSchemaFormat.CURRENCY,
        GeneralModel.JSONSchemaFormat.RATING,
        GeneralModel.JSONSchemaFormat.RANGE,
        GeneralModel.JSONSchemaFormat.NUMERIC_RANGE
      ]
    },
    {
      title: TRANS.client.dataTypes.categories.dates,
      list: [
        GeneralModel.JSONSchemaFormat.TIME,
        GeneralModel.JSONSchemaFormat.DATE,
        GeneralModel.JSONSchemaFormat.DATE_TIME_U,
        GeneralModel.JSONSchemaFormat.WEEK,
        GeneralModel.JSONSchemaFormat.MONTH
      ]
    },
    {
      title: TRANS.client.dataTypes.categories.options,
      list: [
        GeneralModel.JSONSchemaFormat.STRING_OPTION_LIST,
        GeneralModel.JSONSchemaFormat.INLINE_STRING_OPTION_LIST,
        GeneralModel.JSONSchemaFormat.COLLECTION_FIELD,
        GeneralModel.JSONSchemaFormat.NUMBER_OPTION_LIST,
        GeneralModel.JSONSchemaFormat.MULTI_OPTION_LIST,
        GeneralModel.JSONSchemaFormat.MULTI_OPTION_LIST_ALT
      ]
    },
    {
      title: TRANS.client.dataTypes.categories.controls,
      list: [
        GeneralModel.JSONSchemaFormat.COLOR,
        GeneralModel.JSONSchemaFormat.ICON_IMAGE,
        GeneralModel.JSONSchemaFormat.CHECKBOX,
        GeneralModel.JSONSchemaFormat.SWITCH
      ]
    },
    {
      title: TRANS.client.dataTypes.categories.contact,
      list: [GeneralModel.JSONSchemaFormat.EMAIL, GeneralModel.JSONSchemaFormat.PHONE_NUMBER, GeneralModel.JSONSchemaFormat.ADDRESS]
    },
    {
      title: TRANS.client.dataTypes.categories.share,
      list: [GeneralModel.JSONSchemaFormat.URL, GeneralModel.JSONSchemaFormat.FILE_LIST, GeneralModel.JSONSchemaFormat.FILE]
    },
    {
      title: TRANS.client.dataTypes.categories.raw,
      list: [
        GeneralModel.JSONSchemaFormat.SQL,
        GeneralModel.JSONSchemaFormat.XML,
        GeneralModel.JSONSchemaFormat.YAML,
        GeneralModel.JSONSchemaFormat.GRAPHQL,
        GeneralModel.JSONSchemaFormat.JSON
      ]
    },
    {
      title: TRANS.client.dataTypes.categories.data,
      list: [
        GeneralModel.JSONSchemaFormat.ARRAY,
        GeneralModel.JSONSchemaFormat.OBJECT,
        GeneralModel.JSONSchemaFormat.COLLECTION_FILTER,
        GeneralModel.JSONSchemaFormat.COLLECTION_LOOKUP
      ]
    }
  ];
  const usedList = groupList.reduce((total, curr) => [...total, ...curr.list], []) as TemplateName[];
  const unusedList = templateNameList.filter(templateName => !usedList.includes(templateName)).sort((a, b) => a.localeCompare(b)) as TemplateName[];
  return [
    ...groupList,
    {
      title: TRANS.client.dataTypes.categories.misc,
      list: Array.from(new Set(unusedList)).filter(
        f =>
          ![
            GeneralModel.JSONSchemaFormat.ASSOCIATION,
            GeneralModel.JSONSchemaFormat.COLLECTION_RECORD,
            GeneralModel.JSONSchemaFormat.BARCODE,
            GeneralModel.JSONSchemaFormat.QR,
            GeneralModel.JSONSchemaFormat.INFO_BLOCK
          ].includes(f)
      )
    }
  ] as { title: string; list: TemplateName[] }[];
})();

export const templateOptions = templateGroupConfig
  .reduce(
    (total, config) => [
      ...total,
      config.list.map(templateName => ({
        id: templateName,
        item: {
          title: getDataTypeLabel(templateName),
          description: '',
          image: getDataTypeIcon(templateName),
          color: colorPerType[GeneralModel.formatToTypeMap[templateName]]
        },
        group: config.title
      }))
    ],
    []
  )
  .flat() as ComponentProps<typeof Adder>['options'];

const stringOptionListProperties = {
  default: { ...schemaFieldMap.default, type: 'string', format: GeneralModel.JSONSchemaFormat.TEXT },
  metadata: {
    ...metadata,
    properties: {
      ...metadata.properties,
      invalid,
      fieldSize,
      disabled,
      shouldCleanHidden,
      disabledType,
      optionList: {
        ...optionList,
        items: {
          ...optionList.items,
          properties: {
            ...optionList.items.properties,
            value: { $id: createUUID(), label: TRANS.client.fields.titles.value, type: 'string' },
            label: { $id: createUUID(), label: TRANS.client.fields.titles.label, type: 'string' }
          }
        }
      },
      calculation,
      mask,
      color,
      icon
    }
  }
} as GeneralModel.JSONSchema['properties'];

export const templatePropertiesMap: Record<TemplateName, GeneralModel.JSONSchema['properties']> = {
  [GeneralModel.JSONSchemaFormat.ADDRESS]: {
    required: {
      type: 'array',
      label: TRANS.client.schemaEditor.fields.titles.address.required,
      description: TRANS.client.schemaEditor.fields.descriptions.address.required,
      format: GeneralModel.JSONSchemaFormat.MULTI_OPTION_LIST,
      metadata: { detailGroupId: creatorDetailGroupMap.validation.id },
      items: {
        format: GeneralModel.JSONSchemaFormat.STRING_OPTION_LIST,
        type: 'string',
        metadata: {
          detailGroupId: creatorDetailGroupMap.validation.id,
          optionList: Object.entries(getTypeDefaultProps('object', GeneralModel.JSONSchemaFormat.ADDRESS).properties)
            .filter(([_, p]) => !p.metadata?.hidden)
            .map(([k, p]) => ({ label: p.label, value: k }))
        }
      }
    },
    metadata: { ...metadata, properties: { fieldSize, disabled, shouldCleanHidden, calculation, invalid, color, icon, disabledType } }
  },
  [GeneralModel.JSONSchemaFormat.TEXT]: {
    minLength,
    maxLength,
    pattern,
    default: { ...schemaFieldMap.default, type: 'string', format: GeneralModel.JSONSchemaFormat.TEXT },
    metadata: { ...metadata, properties: { fieldSize, disabled, shouldCleanHidden, calculation, mask, color, icon, invalid, suggestionList, disabledType } }
  },
  [GeneralModel.JSONSchemaFormat.COLLECTION_LOOKUP]: {
    pattern,
    default: { ...schemaFieldMap.default, type: 'string', format: GeneralModel.JSONSchemaFormat.TEXT },
    metadata: {
      ...metadata,
      properties: {
        invalid,
        fieldSize,
        disabled,
        color,
        icon,
        disabledType,
        collectionId: {
          type: 'string',
          title: TRANS.client.schemaEditor.fields.titles.collectionId,
          info: TRANS.client.schemaEditor.fields.descriptions.collectionLookup.collectionId,
          format: GeneralModel.JSONSchemaFormat.COLLECTION_LOOKUP,
          metadata: { detailGroupId: creatorDetailGroupMap.general.id, collectionId: ApiModel.ApiEntity.ENTITY, allowFormula: true }
        },
        fetchCriteria: {
          type: 'object',
          title: TRANS.client.schemaEditor.fields.titles.fetchCriteria,
          info: TRANS.client.schemaEditor.fields.descriptions.collectionLookup.fetchCriteria,
          format: GeneralModel.JSONSchemaFormat.EVALUATION,
          metadata: { detailGroupId: creatorDetailGroupMap.general.id, allowFormula: true }
        },
        calculation,
        shouldCleanHidden,
        disableFreeText: {
          type: 'boolean',
          title: TRANS.client.schemaEditor.fields.titles.disableFreeText,
          info: TRANS.client.schemaEditor.fields.descriptions.collectionLookup.disableFreeText,
          metadata: { detailGroupId: creatorDetailGroupMap.general.id }
        },
        optionList: {
          ...optionList,
          label: TRANS.client.schemaEditor.fields.titles.additionalOptions,
          info: TRANS.client.schemaEditor.fields.descriptions.collectionLookup.optionList,
          items: {
            ...optionList.items,
            properties: {
              ...optionList.items.properties,
              value: { $id: createUUID(), type: 'string' },
              label: { $id: createUUID(), type: 'string' }
            }
          },
          metadata: {
            ...optionList.metadata,
            fieldSize: GeneralModel.FieldSize.FULL
          }
        }
      }
    }
  },
  [GeneralModel.JSONSchemaFormat.COLLECTION_FIELD]: {
    pattern,
    default: { ...schemaFieldMap.default, type: 'string', format: GeneralModel.JSONSchemaFormat.TEXT },
    metadata: {
      ...metadata,
      properties: {
        invalid,
        fieldSize,
        color,
        icon,
        disabledType,
        collectionId: {
          type: 'string',
          title: TRANS.client.schemaEditor.fields.titles.collectionId,
          info: TRANS.client.schemaEditor.fields.descriptions.collectionLookup.collectionId,
          format: GeneralModel.JSONSchemaFormat.COLLECTION_LOOKUP,
          metadata: { detailGroupId: creatorDetailGroupMap.general.id, collectionId: ApiModel.ApiEntity.ENTITY, allowFormula: true }
        },
        calculation,
        shouldCleanHidden,
        condition: {
          type: 'any',
          format: GeneralModel.JSONSchemaFormat.EVALUATION,
          label: TRANS.client.schemaEditor.fields.titles.excludeFields,
          info: TRANS.client.schemaEditor.fields.descriptions.collectionField.condition,
          metadata: { detailGroupId: creatorDetailGroupMap.validation.id }
        },
        disabled
      }
    }
  },
  [GeneralModel.JSONSchemaFormat.QR]: {
    default: { ...schemaFieldMap.default, type: 'string', format: GeneralModel.JSONSchemaFormat.QR },
    metadata: { ...metadata, properties: { fieldSize, invalid, disabled, shouldCleanHidden, calculation, color, icon, suggestionList, disabledType } }
  },
  [GeneralModel.JSONSchemaFormat.BARCODE]: {
    default: { ...schemaFieldMap.default, type: 'string', format: GeneralModel.JSONSchemaFormat.BARCODE },
    metadata: { ...metadata, properties: { fieldSize, invalid, disabled, shouldCleanHidden, calculation, color, icon, suggestionList, disabledType } }
  },
  [GeneralModel.JSONSchemaFormat.PHONE_NUMBER]: {
    default: { ...schemaFieldMap.default, type: 'string', format: GeneralModel.JSONSchemaFormat.PHONE_NUMBER },
    metadata: { ...metadata, properties: { fieldSize, invalid, disabled, shouldCleanHidden, calculation, color, icon, suggestionList, disabledType } }
  },
  [GeneralModel.JSONSchemaFormat.COLLECTION_FILTER]: {
    default: { ...schemaFieldMap.default, type: 'object', format: GeneralModel.JSONSchemaFormat.JSON },
    metadata: {
      ...metadata,
      properties: {
        ...metadata.properties,
        color,
        icon,
        fieldSize,
        disabled,
        calculation,
        invalid,
        disabledType,
        entity: {
          type: 'object',
          title: TRANS.client.schemaEditor.fields.titles.model,
          format: GeneralModel.JSONSchemaFormat.JSON,
          metadata: { detailGroupId: creatorDetailGroupMap.general.id }
        },
        collectionId: {
          type: 'string',
          title: TRANS.client.schemaEditor.fields.titles.collectionId,
          info: TRANS.client.schemaEditor.fields.descriptions.collectionFilter.collectionId,
          format: GeneralModel.JSONSchemaFormat.COLLECTION_LOOKUP,
          metadata: { collectionId: ApiModel.ApiEntity.ENTITY, detailGroupId: creatorDetailGroupMap.general.id, allowFormula: true }
        }
      }
    }
  },
  [GeneralModel.JSONSchemaFormat.INLINE_STRING_OPTION_LIST]: stringOptionListProperties,
  [GeneralModel.JSONSchemaFormat.STRING_OPTION_LIST]: stringOptionListProperties,
  [GeneralModel.JSONSchemaFormat.COLOR]: {
    default: { ...schemaFieldMap.default, type: 'string', format: GeneralModel.JSONSchemaFormat.COLOR },
    metadata: { ...metadata, properties: { fieldSize, invalid, disabled, shouldCleanHidden, calculation, color, disabledType } }
  },
  [GeneralModel.JSONSchemaFormat.ICON_IMAGE]: {
    default: { ...schemaFieldMap.default, type: 'string', format: GeneralModel.JSONSchemaFormat.ICON_IMAGE },
    metadata: {
      ...metadata,
      properties: {
        fieldSize,
        invalid,
        disabled,
        shouldCleanHidden,
        calculation,
        color,
        disabledType,
        expanded: {
          ...expanded,
          type: 'boolean',
          title: TRANS.client.schemaEditor.fields.titles.expandedImg
        }
      }
    }
  },
  [GeneralModel.JSONSchemaFormat.DATE]: {
    default: { ...schemaFieldMap.default, type: 'string', format: GeneralModel.JSONSchemaFormat.DATE },
    metadata: { ...metadata, properties: { fieldSize, invalid, disabled, shouldCleanHidden, calculation, mask, color, icon, disabledType } }
  },
  [GeneralModel.JSONSchemaFormat.DATE_TIME_U]: {
    default: { ...schemaFieldMap.default, type: 'string', format: GeneralModel.JSONSchemaFormat.DATE_TIME_U },
    metadata: { ...metadata, properties: { fieldSize, invalid, disabled, shouldCleanHidden, calculation, mask, color, icon, disabledType } }
  },
  [GeneralModel.JSONSchemaFormat.EMAIL]: {
    default: { ...schemaFieldMap.default, type: 'string', format: GeneralModel.JSONSchemaFormat.EMAIL },
    metadata: { ...metadata, properties: { fieldSize, invalid, disabled, shouldCleanHidden, calculation, color, suggestionList, disabledType } }
  },
  [GeneralModel.JSONSchemaFormat.MONTH]: {
    default: { ...schemaFieldMap.default, type: 'string', format: GeneralModel.JSONSchemaFormat.MONTH },
    metadata: { ...metadata, properties: { fieldSize, invalid, disabled, shouldCleanHidden, calculation, mask, color, icon, disabledType } }
  },
  [GeneralModel.JSONSchemaFormat.WEEK]: {
    default: { ...schemaFieldMap.default, type: 'string', format: GeneralModel.JSONSchemaFormat.WEEK },
    metadata: { ...metadata, properties: { fieldSize, invalid, disabled, shouldCleanHidden, calculation, mask, color, icon, disabledType } }
  },
  [GeneralModel.JSONSchemaFormat.TIME]: {
    default: { ...schemaFieldMap.default, type: 'string', format: GeneralModel.JSONSchemaFormat.TIME },
    metadata: { ...metadata, properties: { fieldSize, invalid, disabled, shouldCleanHidden, calculation, mask, color, icon, disabledType } }
  },
  [GeneralModel.JSONSchemaFormat.PASSWORD]: {
    minLength,
    maxLength,
    pattern,
    metadata: { ...metadata, properties: { fieldSize, invalid, disabled, shouldCleanHidden, calculation, mask, color, icon, disabledType } }
  },
  [GeneralModel.JSONSchemaFormat.URL]: {
    minLength,
    maxLength,
    pattern,
    default: { ...schemaFieldMap.default, type: 'string', format: GeneralModel.JSONSchemaFormat.URL },
    metadata: { ...metadata, properties: { fieldSize, invalid, disabled, shouldCleanHidden, calculation, color, icon, suggestionList, disabledType } }
  },
  [GeneralModel.JSONSchemaFormat.MULTILINE]: {
    minLength,
    maxLength,
    pattern,
    default: { ...schemaFieldMap.default, type: 'string', format: GeneralModel.JSONSchemaFormat.MULTILINE },
    metadata: {
      ...metadata,
      properties: { fieldSize, invalid, disabled, allowFileInput, shouldCleanHidden, calculation, mask, color, icon, unlimitedHeight, disabledType }
    }
  },
  [GeneralModel.JSONSchemaFormat.RICH_TEXT]: {
    default: { ...schemaFieldMap.default, type: 'string', format: GeneralModel.JSONSchemaFormat.RICH_TEXT },
    metadata: { ...metadata, properties: { fieldSize, invalid, disabled, shouldCleanHidden, calculation, color, icon, unlimitedHeight, disabledType } }
  },
  [GeneralModel.JSONSchemaFormat.INFO_BLOCK]: {
    description: { metadata: { hidden: true } },
    info: { metadata: { hidden: true } },
    metadata: {
      ...metadata,
      properties: {
        globalSearchable: { metadata: { hidden: true } },
        detailPriority: { metadata: { hidden: true } },
        fieldSize,
        color,
        unlimitedHeight,
        fixedValue: {
          type: 'string',
          label: TRANS.client.schemaEditor.fields.titles.fixedValue,
          format: GeneralModel.JSONSchemaFormat.RICH_TEXT,
          metadata: { allowFormula: true, fieldSize: GeneralModel.FieldSize.FULL, unlimitedHeight: true, detailGroupId: creatorDetailGroupMap.general.id }
        }
      }
    }
  },
  [GeneralModel.JSONSchemaFormat.SQL]: {
    minLength,
    maxLength,
    pattern,
    default: { ...schemaFieldMap.default, type: 'string', format: GeneralModel.JSONSchemaFormat.SQL },
    metadata: {
      ...metadata,
      properties: { fieldSize, invalid, disabled, allowFileInput, shouldCleanHidden, calculation, mask, color, icon, expanded, disabledType }
    }
  },
  [GeneralModel.JSONSchemaFormat.GRAPHQL]: {
    minLength,
    maxLength,
    pattern,
    default: { ...schemaFieldMap.default, type: 'string', format: GeneralModel.JSONSchemaFormat.GRAPHQL },
    metadata: {
      ...metadata,
      properties: { fieldSize, invalid, disabled, allowFileInput, shouldCleanHidden, calculation, mask, color, icon, expanded, disabledType }
    }
  },
  [GeneralModel.JSONSchemaFormat.YAML]: {
    minLength,
    maxLength,
    pattern,
    default: { ...schemaFieldMap.default, type: 'string', format: GeneralModel.JSONSchemaFormat.YAML },
    metadata: {
      ...metadata,
      properties: { fieldSize, invalid, disabled, allowFileInput, shouldCleanHidden, calculation, mask, color, icon, expanded, disabledType }
    }
  },
  [GeneralModel.JSONSchemaFormat.XML]: {
    minLength,
    maxLength,
    pattern,
    default: { ...schemaFieldMap.default, type: 'string', format: GeneralModel.JSONSchemaFormat.XML },
    metadata: {
      ...metadata,
      properties: { fieldSize, invalid, disabled, allowFileInput, shouldCleanHidden, calculation, mask, color, icon, expanded, disabledType }
    }
  },
  [GeneralModel.JSONSchemaFormat.NUMBER]: {
    minimum,
    maximum,
    multipleOf,
    default: { ...schemaFieldMap.default, type: 'number', format: GeneralModel.JSONSchemaFormat.NUMBER },
    metadata: {
      ...metadata,
      properties: {
        fieldSize,
        invalid,
        disabled,
        shouldCleanHidden,
        calculation,
        mask,
        color,
        icon,
        disabledType,
        suggestionList: {
          ...suggestionList,
          items: {
            ...suggestionList.items,
            properties: { ...suggestionList.items.properties, value: { $id: createUUID(), type: 'number' } }
          }
        }
      }
    }
  },
  [GeneralModel.JSONSchemaFormat.NUMBER_OPTION_LIST]: {
    default: { ...schemaFieldMap.default, type: 'number', format: GeneralModel.JSONSchemaFormat.NUMBER },
    metadata: {
      ...metadata,
      properties: {
        ...metadata.properties,
        invalid,
        fieldSize,
        disabled,
        shouldCleanHidden,
        disabledType,
        optionList: {
          ...optionList,
          items: {
            ...optionList.items,
            properties: {
              ...optionList.items.properties,
              value: { $id: createUUID(), label: TRANS.client.fields.titles.value, type: 'number' },
              label: { $id: createUUID(), label: TRANS.client.fields.titles.label, type: 'string' }
            }
          }
        },
        calculation,
        mask,
        color
      }
    }
  },
  [GeneralModel.JSONSchemaFormat.RANGE]: {
    minimum,
    maximum,
    multipleOf,
    default: {
      ...schemaFieldMap.default,
      type: 'number',
      format: GeneralModel.JSONSchemaFormat.NUMBER
    },
    metadata: {
      ...metadata,
      properties: {
        invalid,
        fieldSize,
        disabled,
        shouldCleanHidden,
        calculation,
        mask,
        color,
        icon,
        disabledType,
        subtype: {
          ...subtype,
          type: 'string',
          label: TRANS.client.schemaEditor.fields.titles.sliderType,
          format: GeneralModel.JSONSchemaFormat.INLINE_STRING_OPTION_LIST,
          metadata: {
            ...subtype.metadata,
            optionList: [
              { value: GeneralModel.JSONSchemaSubtype.CONTINUOUS, label: 'Continuous' },
              { value: GeneralModel.JSONSchemaSubtype.STEPPED, label: 'Stepped' }
            ]
          }
        }
      }
    }
  },
  [GeneralModel.JSONSchemaFormat.NUMERIC_RANGE]: {
    minimum,
    maximum,
    multipleOf,
    default: {
      ...schemaFieldMap.default,
      type: 'array',
      format: GeneralModel.JSONSchemaFormat.ARRAY,
      minItems: 2,
      maxItems: 2,
      items: { type: 'number', format: GeneralModel.JSONSchemaFormat.NUMBER, label: TRANS.client.schemaEditor.fields.titles.value }
    },
    metadata: {
      ...metadata,
      properties: {
        invalid,
        fieldSize,
        disabled,
        shouldCleanHidden,
        calculation,
        mask,
        color,
        icon,
        disabledType,
        subtype: {
          ...subtype,
          type: 'string',
          label: TRANS.client.schemaEditor.fields.titles.sliderType,
          format: GeneralModel.JSONSchemaFormat.INLINE_STRING_OPTION_LIST,
          metadata: {
            ...subtype.metadata,
            optionList: [
              { value: GeneralModel.JSONSchemaSubtype.CONTINUOUS, label: 'Continuous' },
              { value: GeneralModel.JSONSchemaSubtype.STEPPED, label: 'Stepped' }
            ]
          }
        }
      }
    }
  },
  [GeneralModel.JSONSchemaFormat.RATING]: {
    default: {
      ...schemaFieldMap.default,
      type: 'number',
      format: GeneralModel.JSONSchemaFormat.NUMBER,
      metadata: {
        ...schemaFieldMap.default.metadata,
        invalid: { $cyf_or: [{ $cyf_gt: ['{{event.value}}', '{{event.item.maximum}}'] }, { $cyf_lt: ['{{event.value}}', 0] }] }
      }
    },
    maximum: {
      ...maximum,
      info: TRANS.client.schemaEditor.fields.descriptions.rating.maximum,
      format: GeneralModel.JSONSchemaFormat.NUMBER_OPTION_LIST,
      metadata: { ...maximum.metadata, optionList: Array.from(Array(10)).map((_, index) => ({ value: index + 1 })) }
    },
    metadata: {
      ...metadata,
      properties: {
        fieldSize,
        invalid,
        disabled,
        shouldCleanHidden,
        calculation,
        disabledType,
        color: { ...color, label: TRANS.client.schemaEditor.fields.titles.selectedColor },
        secondaryColor: { ...secondaryColor, label: TRANS.client.schemaEditor.fields.descriptions.rating.emptyColor },
        icon
      }
    }
  },
  [GeneralModel.JSONSchemaFormat.CURRENCY]: {
    default: { ...schemaFieldMap.default, type: 'number', format: GeneralModel.JSONSchemaFormat.CURRENCY },
    minimum,
    maximum,
    metadata: {
      ...metadata,
      properties: {
        fieldSize,
        invalid,
        disabled,
        shouldCleanHidden,
        calculation,
        mask,
        color,
        icon,
        disabledType,
        suggestionList: {
          ...suggestionList,
          items: {
            ...suggestionList.items,
            properties: { ...suggestionList.items.properties, value: { $id: createUUID(), type: 'number' } }
          }
        }
      }
    }
  },
  [GeneralModel.JSONSchemaFormat.CHECKBOX]: {
    default: { ...schemaFieldMap.default, type: 'boolean', format: GeneralModel.JSONSchemaFormat.CHECKBOX },
    metadata: {
      ...metadata,
      properties: { fieldSize, booleanLabelTrue, booleanLabelFalse, invalid, disabled, shouldCleanHidden, calculation, color, icon, disabledType }
    }
  },
  [GeneralModel.JSONSchemaFormat.SWITCH]: {
    default: { ...schemaFieldMap.default, type: 'boolean', format: GeneralModel.JSONSchemaFormat.SWITCH },
    metadata: { ...metadata, properties: { fieldSize, booleanLabelTrue, booleanLabelFalse, invalid, disabled, shouldCleanHidden, calculation, color, icon } }
  },
  [GeneralModel.JSONSchemaFormat.OBJECT]: {
    metadata: {
      ...metadata,
      properties: {
        fieldSize,
        subtype: {
          ...subtype,
          type: 'string',
          label: TRANS.client.schemaEditor.fields.titles.display,
          metadata: {
            ...subtype.metadata,
            detailGroupId: creatorDetailGroupMap.appearance.id,
            optionList: [
              {
                value: GeneralModel.JSONSchemaSubtype.UNFRAMED,
                label: TRANS.client.schemaEditor.fields.titles.unframed,
                description: TRANS.client.schemaEditor.fields.descriptions.object.subtype.unframed
              },
              {
                value: GeneralModel.JSONSchemaSubtype.FRAMED,
                label: TRANS.client.schemaEditor.fields.titles.framed,
                description: TRANS.client.schemaEditor.fields.descriptions.object.subtype.framed
              }
            ]
          }
        },
        invalid,
        disabled,
        shouldCleanHidden,
        color,
        startsCollapsed,
        disabledType
      }
    }
  },
  [GeneralModel.JSONSchemaFormat.COLLECTION_RECORD]: {
    metadata: {
      ...metadata,
      properties: {
        fieldSize,
        subtype: {
          ...subtype,
          type: 'string',
          label: TRANS.client.schemaEditor.fields.titles.display,
          metadata: {
            ...subtype.metadata,
            detailGroupId: creatorDetailGroupMap.appearance.id,
            optionList: [
              {
                value: GeneralModel.JSONSchemaSubtype.UNFRAMED,
                label: TRANS.client.schemaEditor.fields.titles.unframed,
                description: TRANS.client.schemaEditor.fields.descriptions.object.subtype.unframed
              },
              {
                value: GeneralModel.JSONSchemaSubtype.FRAMED,
                label: TRANS.client.schemaEditor.fields.titles.framed,
                description: TRANS.client.schemaEditor.fields.descriptions.object.subtype.framed
              }
            ]
          }
        },
        collectionId: {
          type: 'string',
          label: TRANS.client.schemaEditor.fields.titles.collectionId,
          format: GeneralModel.JSONSchemaFormat.STRING_OPTION_LIST,
          metadata: {
            detailGroupId: creatorDetailGroupMap.general.id,
            allowFormula: true,
            optionList: [{ value: ApiModel.ApiEntity.ENTITY }, { value: ApiModel.ApiEntity.VIEW }, { value: ApiModel.ApiEntity.FLOW }]
          }
        },
        recordId: {
          type: 'string',
          label: TRANS.client.schemaEditor.fields.titles.recodId,
          format: GeneralModel.JSONSchemaFormat.COLLECTION_LOOKUP,
          metadata: {
            detailGroupId: creatorDetailGroupMap.general.id,
            allowFormula: true,
            collectionId: '{{event.item.metadata.collectionId}}'
          }
        },
        invalid,
        disabled,
        color,
        startsCollapsed,
        disabledType
      }
    },
    properties: {}
  },
  [GeneralModel.JSONSchemaFormat.FILE]: {
    metadata: {
      ...metadata,
      properties: {
        fieldSize,
        invalid,
        disabled,
        shouldCleanHidden,
        color,
        icon,
        disabledType,
        allowedFileTypes: {
          ...allowedFileTypes,
          format: GeneralModel.JSONSchemaFormat.MULTI_OPTION_LIST,
          label: TRANS.client.schemaEditor.fields.titles.allowedFileTypes,
          info: TRANS.client.schemaEditor.fields.descriptions.file.allowedFileTypes,
          metadata: { detailGroupId: creatorDetailGroupMap.validation.id, fieldSize: GeneralModel.FieldSize.FULL },
          items: {
            format: GeneralModel.JSONSchemaFormat.STRING_OPTION_LIST,
            type: 'string',
            metadata: {
              detailGroupId: creatorDetailGroupMap.validation.id,
              optionList: fileTypeFormatList
            }
          }
        }
      }
    }
  },
  [GeneralModel.JSONSchemaFormat.ARRAY]: {
    maxItems,
    minItems,
    uniqueItems,
    metadata: {
      ...metadata,
      properties: { fieldSize, color, invalid, shouldCleanHidden, disabled, unlimitedHeight, ctaConfig, disabledType, startsCollapsed, isCollapsible }
    }
  },
  [GeneralModel.JSONSchemaFormat.FILE_LIST]: {
    maxItems,
    minItems,
    metadata: {
      ...metadata,
      properties: {
        fieldSize,
        invalid,
        disabled,
        shouldCleanHidden,
        disabledType,
        startsCollapsed,
        isCollapsible,
        allowedFileTypes: {
          ...allowedFileTypes,
          format: GeneralModel.JSONSchemaFormat.MULTI_OPTION_LIST,
          label: TRANS.client.schemaEditor.fields.titles.allowedFileTypes,
          info: TRANS.client.schemaEditor.fields.descriptions.file.allowedFileTypes,
          metadata: { detailGroupId: creatorDetailGroupMap.validation.id, fieldSize: GeneralModel.FieldSize.FULL },
          items: {
            format: GeneralModel.JSONSchemaFormat.STRING_OPTION_LIST,
            type: 'string',
            metadata: {
              detailGroupId: creatorDetailGroupMap.validation.id,
              optionList: fileTypeFormatList
            }
          }
        }
      }
    }
  },
  [GeneralModel.JSONSchemaFormat.MULTI_OPTION_LIST]: {
    maxItems,
    minItems,
    metadata: { ...metadata, properties: { fieldSize, invalid, disabled, shouldCleanHidden, disabledType } }
  },
  [GeneralModel.JSONSchemaFormat.MULTI_OPTION_LIST_ALT]: {
    maxItems,
    minItems,
    metadata: { ...metadata, properties: { fieldSize, invalid, disabled, shouldCleanHidden } }
  },
  [GeneralModel.JSONSchemaFormat.JSON]: {
    default: { ...schemaFieldMap.default, type: 'object', format: GeneralModel.JSONSchemaFormat.JSON },
    metadata: { ...metadata, properties: { fieldSize, invalid, disabled, allowFileInput, shouldCleanHidden, calculation, color, icon, expanded, disabledType } }
  },
  [GeneralModel.JSONSchemaFormat.ASSOCIATION]: {
    metadata: {
      ...metadata,
      required: ['subtype'],
      properties: {
        infoBlockVisibility: {
          title: 'Visibility',
          type: 'string',
          format: GeneralModel.JSONSchemaFormat.INFO_BLOCK,
          metadata: {
            detailOrder: 1,
            fixedValue: '<h5>Visibility</h5>',
            fieldSize: GeneralModel.FieldSize.FULL,
            unlimitedHeight: true,
            detailGroupId: creatorDetailGroupMap.general.id
          }
        },
        hidden: {
          ...hidden,
          metadata: {
            ...hidden.metadata,
            detailOrder: 2
          }
        },
        infoBlockDisabled: {
          title: 'Disabled',
          type: 'string',
          format: GeneralModel.JSONSchemaFormat.INFO_BLOCK,
          metadata: {
            detailOrder: 3,
            fixedValue: '<h5>Disabled</h5>',
            fieldSize: GeneralModel.FieldSize.FULL,
            unlimitedHeight: true,
            detailGroupId: creatorDetailGroupMap.general.id
          }
        },
        disabled: {
          ...disabled,
          metadata: {
            ...disabled.metadata,
            detailOrder: 4
          }
        },
        disabledType: {
          ...disabledType,
          metadata: {
            ...disabledType.metadata,
            detailOrder: 5
          }
        },
        infoBlockSize: {
          title: 'Size',
          type: 'string',
          format: GeneralModel.JSONSchemaFormat.INFO_BLOCK,
          metadata: {
            detailOrder: 1,
            fixedValue: '<h5>Size</h5>',
            fieldSize: GeneralModel.FieldSize.FULL,
            unlimitedHeight: true,
            detailGroupId: creatorDetailGroupMap.appearance.id
          }
        },
        fieldSize,
        startsCollapsed,
        invalid,
        subtype: {
          ...subtype,
          label: TRANS.client.schemaEditor.fields.titles.assType.title,
          format: GeneralModel.JSONSchemaFormat.STRING_OPTION_LIST,
          metadata: {
            ...subtype.metadata,
            invalid: {
              $cyf_if: [
                {
                  $cyf_and: [
                    { $cyf_gt: ['{{event.item.metadata.association.max}}', 1] },
                    { $cyf_eq: ['{{event.item.metadata.subtype}}', GeneralModel.JSONSchemaSubtype.ASSOCIATION_DROPDOWN] }
                  ]
                },
                TRANS.client.schemaEditor.fields.descriptions.association.subtype.dropdown,
                false
              ]
            },
            optionList: [
              {
                label: TRANS.client.schemaEditor.fields.titles.assType.list,
                value: GeneralModel.JSONSchemaSubtype.ASSOCIATION_LIST,
                image: 'format_list_bulleted'
              },
              { label: TRANS.client.schemaEditor.fields.titles.assType.kpi, value: GeneralModel.JSONSchemaSubtype.ASSOCIATION_KPI, image: 'pie_chart' },
              {
                label: TRANS.client.schemaEditor.fields.titles.assType.table,
                value: GeneralModel.JSONSchemaSubtype.ASSOCIATION_TABLE,
                image: 'table_rows_narrow'
              },
              { label: TRANS.client.schemaEditor.fields.titles.assType.card, value: GeneralModel.JSONSchemaSubtype.ASSOCIATION_CARD, image: 'view_agenda' },
              { label: TRANS.client.schemaEditor.fields.titles.assType.grid, value: GeneralModel.JSONSchemaSubtype.ASSOCIATION_GRID, image: 'grid_on' },
              {
                label: TRANS.client.schemaEditor.fields.titles.assType.dropdown,
                value: GeneralModel.JSONSchemaSubtype.ASSOCIATION_DROPDOWN,
                image: 'counter_1',
                description: TRANS.client.schemaEditor.fields.descriptions.association.subtype.dropdown
              }
            ],
            detailGroupId: creatorDetailGroupMap.association.id,
            detailOrder: 1
          }
        },
        association: {
          type: 'object',
          label: ' ',
          metadata: { detailGroupId: creatorDetailGroupMap.association.id, detailOrder: 2 },
          properties: {
            cursor: {
              title: '   ',
              type: 'object',
              metadata: {
                detailOrder: 1
              },
              properties: {
                collectionId: {
                  title: TRANS.client.schemaEditor.fields.titles.collectionId,
                  type: 'string',
                  format: GeneralModel.JSONSchemaFormat.COLLECTION_LOOKUP,
                  metadata: {
                    detailOrder: 1,
                    disabled: true,
                    collectionId: ApiModel.ApiEntity.ENTITY
                  }
                },
                infoBlockListOptions: {
                  title: 'List options',
                  type: 'string',
                  format: GeneralModel.JSONSchemaFormat.INFO_BLOCK,
                  metadata: {
                    detailOrder: 2,
                    fixedValue: '<h5>List options</h5>',
                    fieldSize: GeneralModel.FieldSize.FULL,
                    unlimitedHeight: true
                  }
                },
                options: {
                  title: '   ',
                  type: 'object',
                  metadata: {
                    detailOrder: 3
                  },
                  properties: {
                    orderBy: {
                      title: TRANS.client.schemaEditor.fields.titles.orderBy,
                      type: 'string',
                      format: GeneralModel.JSONSchemaFormat.COLLECTION_FIELD,
                      metadata: {
                        collectionId: '{{event.$2.value.collectionId}}',
                        condition: {
                          $not: true,
                          $cyf_in: ['{{event.type}}', 'string', 'number', 'boolean', 'object']
                        },
                        detailOrder: 1
                      }
                    },
                    descending: {
                      title: TRANS.client.schemaEditor.fields.titles.descending,
                      type: 'boolean',
                      format: GeneralModel.JSONSchemaFormat.CHECKBOX,
                      metadata: {
                        detailOrder: 2
                      },
                      default: false
                    },
                    limit: {
                      title: TRANS.client.schemaEditor.fields.titles.limit,
                      type: 'number',
                      minimum: 0,
                      metadata: {
                        detailOrder: 3
                      }
                    }
                  },
                  required: [],
                  additionalProperties: true
                }
              },
              required: [],
              additionalProperties: true
            },
            infoBlockValidation: {
              title: 'Validation',
              type: 'string',
              format: GeneralModel.JSONSchemaFormat.INFO_BLOCK,
              metadata: {
                detailOrder: 2,
                fixedValue: '<h5>Association validation</h5>',
                fieldSize: GeneralModel.FieldSize.FULL,
                unlimitedHeight: true
              }
            },
            min: mergeTruthy(minimum, { metadata: { detailGroupId: '', detailOrder: 3 } }),
            max: mergeTruthy(maximum, { metadata: { detailGroupId: '', detailOrder: 4 } }),
            infoBlockSelectionFilters: {
              title: 'Selection filters',
              type: 'string',
              format: GeneralModel.JSONSchemaFormat.INFO_BLOCK,
              metadata: {
                detailOrder: 5,
                fixedValue: '<h5>Selection filters</h5>',
                fieldSize: GeneralModel.FieldSize.FULL,
                unlimitedHeight: true
              }
            },
            defaultFilter: {
              label: TRANS.client.schemaEditor.fields.titles.defaultFilter,
              info: TRANS.client.schemaEditor.fields.descriptions.association.defaultFilter,
              type: 'object',
              format: GeneralModel.JSONSchemaFormat.COLLECTION_FILTER,
              metadata: { allowFormula: true, collectionId: '{{event.parent.value.cursor.collectionId}}', detailOrder: 6 }
            },
            fixedFilter: {
              label: TRANS.client.schemaEditor.fields.titles.fixedFilter,
              info: TRANS.client.schemaEditor.fields.descriptions.association.fixedFilter,
              type: 'object',
              format: GeneralModel.JSONSchemaFormat.COLLECTION_FILTER,
              metadata: { allowFormula: true, collectionId: '{{event.parent.value.cursor.collectionId}}', detailOrder: 7 }
            },
            infoBlockFields: {
              title: 'Fields',
              type: 'string',
              format: GeneralModel.JSONSchemaFormat.INFO_BLOCK,
              metadata: {
                detailOrder: 8,
                fixedValue: '<h5>Fields</h5>',
                fieldSize: GeneralModel.FieldSize.FULL,
                unlimitedHeight: true
              }
            },
            select: {
              label: TRANS.client.schemaEditor.fields.titles.select,
              info: TRANS.client.schemaEditor.fields.descriptions.association.select,
              type: 'array',
              format: GeneralModel.JSONSchemaFormat.ARRAY,
              metadata: { disabled: { $cyf_toboolean: [{ $cyf_length: ['{{event.$1.value.omit}}'] }] }, detailOrder: 9 },
              items: {
                label: ' ',
                type: 'string',
                format: GeneralModel.JSONSchemaFormat.COLLECTION_FIELD,
                metadata: {
                  collectionId: '{{event.$2.value.cursor.collectionId}}',
                  condition: {
                    $cyf_eq: ['{{event.format}}', 'association']
                  }
                }
              }
            },
            omit: {
              label: TRANS.client.schemaEditor.fields.titles.omit,
              info: TRANS.client.schemaEditor.fields.descriptions.association.omit,
              type: 'array',
              format: GeneralModel.JSONSchemaFormat.ARRAY,
              metadata: { disabled: { $cyf_toboolean: [{ $cyf_length: ['{{event.$1.value.select}}'] }] }, detailOrder: 10 },
              items: {
                label: ' ',
                type: 'string',
                format: GeneralModel.JSONSchemaFormat.COLLECTION_FIELD,
                metadata: {
                  collectionId: '{{event.$2.value.cursor.collectionId}}',
                  condition: {
                    $cyf_eq: ['{{event.format}}', 'association']
                  }
                }
              }
            },
            infoBlockPerformance: {
              title: 'Performance',
              type: 'string',
              format: GeneralModel.JSONSchemaFormat.INFO_BLOCK,
              metadata: {
                detailOrder: 11,
                fixedValue: '<h5>Performance</h5>',
                fieldSize: GeneralModel.FieldSize.FULL,
                unlimitedHeight: true
              }
            },
            preventLoad: {
              label: TRANS.client.schemaEditor.fields.titles.preventLoad,
              info: TRANS.client.schemaEditor.fields.info.association.preventLoad,
              type: 'boolean',
              format: GeneralModel.JSONSchemaFormat.CHECKBOX,
              default: false,
              metadata: {
                detailOrder: 12
              }
            }
          }
        },
        disableCreateNew: {
          label: TRANS.client.schemaEditor.fields.titles.disableCreateNew,
          info: TRANS.client.schemaEditor.fields.info.association.disableCreateNew,
          type: 'boolean',
          format: GeneralModel.JSONSchemaFormat.CHECKBOX,
          default: false,
          metadata: { detailGroupId: creatorDetailGroupMap.actions.id }
        },
        disableLink: {
          label: TRANS.client.schemaEditor.fields.titles.disableLink,
          info: TRANS.client.schemaEditor.fields.info.association.disableLink,
          type: 'boolean',
          format: GeneralModel.JSONSchemaFormat.CHECKBOX,
          default: false,
          metadata: { detailGroupId: creatorDetailGroupMap.actions.id }
        },
        disableUnlink: {
          label: TRANS.client.schemaEditor.fields.titles.disableUnlink,
          info: TRANS.client.schemaEditor.fields.info.association.disableUnlink,
          type: 'boolean',
          format: GeneralModel.JSONSchemaFormat.CHECKBOX,
          default: false,
          metadata: { detailGroupId: creatorDetailGroupMap.actions.id }
        }
      }
    }
  }
};

export const templateMap = Object.fromEntries(
  Object.entries(templatePropertiesMap).map(([key, properties]) => [key, { $id: createUUID(), type: 'object', properties }])
) as Record<TemplateName, GeneralModel.JSONSchema>;
