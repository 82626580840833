import { createSlice } from '@reduxjs/toolkit';
import { ApiModel } from '@cyferd/client-engine';
import type { GeneralModel } from '@models';

export const NOTIFICATIONS_POINTER = 'notifications';
export const NOTIFICATION_LATEST_POINTER = 'notifications_dropdown';
export const NOTIFICATION_CURRENT_POINTER = 'notifications_current';

export const getInitialState = (): GeneralModel.NotificationState => ({
  list: null,
  latest: null,
  current: null
});

const notificationSlice = createSlice({
  name: 'notification',
  initialState: getInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(ApiModel.TriggerActionType.DISPATCH_SET_DATA, (state: GeneralModel.NotificationState, action: GeneralModel.Action) => {
        switch (action.payload.pointer) {
          case NOTIFICATIONS_POINTER:
            state.list = action.payload.value;
            break;
          case NOTIFICATION_LATEST_POINTER:
            state.latest = action.payload.value;
            break;
          case NOTIFICATION_CURRENT_POINTER:
            state.current = action.payload.value?.record;
            break;
        }
      })
      .addCase(ApiModel.TriggerActionType.DISPATCH_NOTIFICATION_READ, (state: GeneralModel.NotificationState, action: GeneralModel.Action) => {
        const updateReadStatus = notification => (notification.id === action.payload?.id ? { ...notification, read: action.payload?.read } : notification);

        state.list = state.list ? { ...state.list, list: state.list.list?.map(updateReadStatus) } : null;

        state.latest = state.latest ? { ...state.latest, list: state.latest.list?.map(updateReadStatus) } : null;
      });
  }
});

export type NotificationsActions = typeof notificationSlice.actions;
export type NotificationsState = ReturnType<typeof getInitialState>;

export const notificationReducer = notificationSlice.reducer;
