import { GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  containerColor: css`
    padding-left: ${GAP.XS};
  `,
  modalEditorContainer: css`
    max-height: 70vh;
    overflow-y: auto;
  `,
  container: ({ unlimitedHeight }: { unlimitedHeight: boolean }) => css`
    flex: 1;
    min-height: 22px;
    overflow-y: auto;
    ${unlimitedHeight ? '' : 'max-height: 375px;'}
  `
};
