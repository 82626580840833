import type { BaseFieldProps, SchemaFormBaseProps } from '@cyferd/client-engine';
import { GeneralModel, ViewModel, getDisabledStates, getSchemaPlaceholder } from '@cyferd/client-engine';
import { useCallback } from 'react';
import { DatasetContainer } from '@components/elements/DatasetContainer';
import { getLabel } from '@utils';
import { styles } from '../styles';
import type { IOptionListItem } from '@components/elements/OptionMenu';
import type { FormulaInputRow } from '@components/elements/Evaluator/resources';
import { shouldAllowFormula } from '@components/elements/Evaluator/resources';
import { EvaluatorWrapper } from '@components/elements/Evaluator/EvaluatorWrapper';
import { InputWrapper } from '@components/elements/InputWrapper';

export const useRenderInputWrapper = ({
  getIds,
  maxColumns,
  allowFormula,
  inputList,
  addDefaults,
  getOptionMenu
}: {
  getIds: Function;
  maxColumns: number;
  allowFormula: boolean;
  inputList: FormulaInputRow[];
  addDefaults: boolean;
  getOptionMenu: (event: BaseFieldProps) => IOptionListItem[];
}): SchemaFormBaseProps['componentRecord']['renderInputWrapper'] =>
  useCallback(
    props => {
      const {
        id,
        children,
        type,
        displayNamePath,
        schema,
        format,
        value,
        color,
        parentType,
        parentSubtype,
        error,
        disabled,
        path,
        required,
        disabledType,
        onChange
      } = props;
      const actualInputList = [...(schema.metadata?.inputList || []), ...(inputList || [])];
      if (type === 'object') {
        return (
          <DatasetContainer
            id={id}
            schema={schema}
            displayNamePath={displayNamePath}
            maxColumns={maxColumns}
            color={color}
            parentType={parentType}
            format={format}
            parentSubtype={parentSubtype}
            errorMessage={error}
            disabled={disabled}
            disabledType={disabledType || schema?.metadata?.disabledType}
            required={required}
            inputList={actualInputList}
            allowFormula={shouldAllowFormula(allowFormula, schema.metadata?.allowFormula)}
            value={value}
            onChange={onChange}
            optionList={getOptionMenu(props)}
          >
            {children}
          </DatasetContainer>
        );
      }

      if (
        type === 'array' &&
        ![
          GeneralModel.JSONSchemaFormat.NUMERIC_RANGE,
          GeneralModel.JSONSchemaFormat.MULTI_OPTION_LIST,
          GeneralModel.JSONSchemaFormat.MULTI_OPTION_LIST_ALT,
          GeneralModel.JSONSchemaFormat.ASSOCIATION
        ].includes(format)
      ) {
        const { isReadonly, isGreyedOut } = getDisabledStates(disabled, disabledType);
        return (
          <EvaluatorWrapper
            allowFormula={shouldAllowFormula(allowFormula, schema.metadata?.allowFormula)}
            value={value}
            onChange={onChange}
            disabled={disabled}
            label={`${getLabel(displayNamePath)} (${value?.length || 0})`}
            inputList={actualInputList}
            format={format}
            openModalOnFocus={true}
            info={schema?.info}
            description={schema?.description}
            color={color}
            disabledType={disabledType || schema?.metadata?.disabledType}
            optionList={getOptionMenu(props)}
          >
            {fixedProps => (
              <InputWrapper
                isContainerInput={true}
                id={id}
                color={color}
                maxColumns={maxColumns}
                isCollapsible={!!value?.length && schema.metadata?.isCollapsible !== false}
                startsCollapsed={!!value?.length && !!schema.metadata?.startsCollapsed}
                label={getLabel(displayNamePath)}
                count={value?.length || 0}
                description={!isReadonly && schema?.description}
                info={schema?.info}
                errorMessage={error}
                disabled={isGreyedOut}
                unframed={false}
                required={required}
                collapsedFallbackDescription={schema?.items?.label || 'Uncollapse to see all'}
                optionList={[
                  format === GeneralModel.JSONSchemaFormat.ARRAY &&
                    !isReadonly && {
                      testid: `add-${path.join('-')}`,
                      label: schema.metadata?.ctaConfig?.label ?? 'Add',
                      image: schema.metadata?.ctaConfig?.image ?? 'add',
                      color: schema.metadata?.ctaConfig?.color,
                      type: schema.metadata?.ctaConfig?.type ?? ViewModel.CTAType.TERTIARY,
                      size: ViewModel.CTASize.SMALL,
                      disabled,
                      important: true,
                      onClick: () => fixedProps.onChange([...(Array.isArray(value) ? value : []), getSchemaPlaceholder(schema?.items, addDefaults)])
                    },
                  ...fixedProps.evaluatorOptionList,
                  ...getOptionMenu(props)
                ]}
              >
                <div css={styles.arrayContent}>{children}</div>
              </InputWrapper>
            )}
          </EvaluatorWrapper>
        );
      }

      return <div {...getIds(id, { nativeHtmlElement: true })}>{children}</div>;
    },
    [addDefaults, allowFormula, getIds, getOptionMenu, inputList, maxColumns]
  );
