import type { PropsWithChildren } from 'react';
import React, { memo, useContext, useMemo } from 'react';
import { GeneralModel, getDisabledStates, useTranslate } from '@cyferd/client-engine';

import { getLabel } from '@utils';
import { ArrayItemContext } from '../../smart/CyForm/components/BaseForm/componentRecord/useRenderArrayItem';
import { EvaluatorWrapper } from '../Evaluator/EvaluatorWrapper';
import type { FormulaInputRow } from '../Evaluator/resources';
import { InputWrapper } from '../InputWrapper';
import type { IOptionListItem } from '../OptionMenu';

const emptyList = [];

export type DatasetContainerProps = PropsWithChildren<{
  id: string;
  displayNamePath: string[];
  value: any;
  schema: GeneralModel.JSONSchema;
  color: GeneralModel.Color.ThemeColor;
  parentType: GeneralModel.JSONSchema['type'];
  parentSubtype: GeneralModel.JSONSchemaSubtype;
  format: GeneralModel.JSONSchema['format'];
  maxColumns?: number;
  disabled?: boolean;
  disabledType?: GeneralModel.DisabledType;
  errorMessage?: string;
  required?: boolean;
  allowFormula?: boolean;
  inputList?: FormulaInputRow[];
  optionList?: IOptionListItem[];
  onChange: (event: any) => void;
}>;

export const DatasetContainer = memo(
  ({
    children,
    id,
    displayNamePath,
    schema,
    color,
    parentType,
    format,
    parentSubtype,
    maxColumns,
    errorMessage,
    disabled,
    allowFormula,
    value,
    inputList,
    required,
    disabledType,
    optionList,
    onChange
  }: DatasetContainerProps) => {
    const { optionList: arrayOptionList } = useContext(ArrayItemContext);
    const { translate } = useTranslate();

    const safeOptionList = useMemo(() => (Array.isArray(optionList) ? optionList : []), [optionList]);
    const subtype = useMemo(() => {
      if (parentType === 'array' && [null, undefined, GeneralModel.JSONSchemaFormat.OBJECT].includes(format)) return GeneralModel.JSONSchemaSubtype.FRAMED;
      return schema.metadata?.subtype;
    }, [format, parentType, schema.metadata?.subtype]);

    const { isReadonly, isGreyedOut } = getDisabledStates(disabled, disabledType);

    const collapsedFallbackDescription = useMemo(
      () =>
        Object.values(schema?.properties || {})
          .map(s => {
            const fieldValue = value?.[s.key];
            if (fieldValue == undefined) return;
            const title = translate(s.title || s.label);
            if (['number', 'string'].includes(s.type)) {
              return title + ': ' + (s.metadata?.optionList?.find(e => e.value === fieldValue)?.label || fieldValue);
            }
            if (s.type === 'boolean' && fieldValue) return title;
            if (s.type === 'array' && fieldValue.length) return `${fieldValue.length} ${title}`;
          })
          .filter(Boolean)
          .join(', '),
      [schema?.properties, value]
    );

    return (
      <ArrayItemContext.Provider
        value={{
          optionList: [null, undefined, GeneralModel.JSONSchemaFormat.OBJECT].includes(format) ? emptyList : arrayOptionList
        }}
      >
        {(() => {
          switch (subtype) {
            case GeneralModel.JSONSchemaSubtype.FRAMED:
              const label = getLabel(
                parentSubtype !== GeneralModel.JSONSchemaSubtype.FRAMED ? displayNamePath : [schema.label || schema.title].filter(Boolean)
              );
              return (
                <EvaluatorWrapper
                  allowFormula={allowFormula}
                  value={value}
                  onChange={onChange}
                  disabled={disabled}
                  label={label}
                  inputList={inputList}
                  format={format}
                  openModalOnFocus={true}
                  info={schema?.info}
                  description={schema?.description}
                  color={color}
                  disabledType={disabledType}
                  optionList={safeOptionList}
                >
                  {fixedProps => (
                    <InputWrapper
                      isContainerInput={true}
                      required={required}
                      id={id}
                      color={color}
                      maxColumns={maxColumns}
                      isCollapsible={schema.metadata?.isCollapsible !== false}
                      startsCollapsed={!!schema.metadata?.startsCollapsed}
                      label={label}
                      description={!isReadonly && schema?.description}
                      info={schema?.info}
                      errorMessage={errorMessage}
                      disabled={isGreyedOut}
                      collapsedFallbackDescription={collapsedFallbackDescription}
                      optionList={[...fixedProps.evaluatorOptionList, ...arrayOptionList, ...safeOptionList]}
                      unframed={false}
                    >
                      {children}
                    </InputWrapper>
                  )}
                </EvaluatorWrapper>
              );
            default:
              return <>{children}</>;
          }
        })()}
      </ArrayItemContext.Provider>
    );
  }
);
