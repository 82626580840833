import { css } from '@emotion/react';
import { COLOR, DROPDOWN_BASE_STYLES, FONT_SIZE, FONT_WEIGHT, GAP, RADIUS } from '@constants';

export const styles = {
  container: css`
    ${DROPDOWN_BASE_STYLES};
    background-color: ${COLOR.TOP_BACKGROUND};
    padding: ${GAP.M} 0;
    max-width: 350px;
    max-height: 500px;
    overflow: auto;

    color: ${COLOR.NEUTRAL_1_5};
    font-size: ${FONT_SIZE.S};
    font-weight: ${FONT_WEIGHT.NORMAL};
  `,
  separator: css`
    border-bottom: 1px solid ${COLOR.NEUTRAL_3_5};
    margin: ${GAP.S} 0;
  `,
  colorBar: css`
    width: 4px;
    height: 100%;
    border-radius: ${RADIUS.XS};
    top: 0;
    bottom: 0;
    left: 0;
    position: absolute;
  `,
  listItem: css`
    min-height: 30px;
    position: relative;
  `,
  customQuickfilter: css`
    display: flex;
    align-items: center;
    gap: ${GAP.S};
    margin-left: 16px;
  `,
  optionQuickFilterTitle: css`
    display: flex;
    align-items: center;
    gap: ${GAP.S};
    margin-bottom: ${GAP.XS};
    margin-left: 16px;
  `,
  optionsContainer: css`
    display: flex;
    flex-direction: column;
    gap: ${GAP.XS};
    margin-left: 40px;
  `,
  option: css`
    display: flex;
    align-items: center;
    gap: ${GAP.S};
  `,
  clickableContainer: css`
    padding: 0 ${GAP.S};
    border-radius: ${RADIUS.XS};
    margin-right: ${GAP.XS};
    cursor: pointer;

    :hover {
      background-color: ${COLOR.NEUTRAL_6};
    }

    button {
      width: ${GAP.L};
      height: ${GAP.L};

      span {
        font-size: ${FONT_SIZE.XM};
      }
    }
  `,
  active: css`
    background-color: ${COLOR.NEUTRAL_5};
    color: ${COLOR.BASE_FOREGROUND};
  `
};
