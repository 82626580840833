import { useContext, useMemo } from 'react';
import type { BaseFieldProps } from '@cyferd/client-engine';
import { ClientEngineContext, GeneralModel, useTranslate, ViewModel } from '@cyferd/client-engine';
import type { EditorContextValue } from '../../../builder/views/shared/EditorHome';
import { EditorContext } from '../../../builder/views/shared/EditorHome';
import { EvaluatorWrapper } from '@components/elements/Evaluator/EvaluatorWrapper';
import { SelectDropdown } from '@components/elements/SelectDropdown';
import { TRANS } from '@constants';

export const ViewComponentInput = ({ type: _type, ...props }: BaseFieldProps & { inputList: any }) => {
  const { item } = useContext<EditorContextValue<ViewModel.View>>(EditorContext);
  const { useViewSelector } = useContext(ClientEngineContext);
  const view = useViewSelector();
  const { translate } = useTranslate();

  const { componentType } = props.schema.metadata || {};

  const { optionList, warning }: { optionList: GeneralModel.InputOption[]; warning: boolean } = useMemo(() => {
    const map: { [k: string]: GeneralModel.InputOption } = {};

    const source = item || view;
    if (source) {
      if (componentType === ViewModel.DisplayName.CY_MODAL) {
        Object.values(source.modals).forEach(({ id, name }) => {
          const label = `${name} (${translate(TRANS.client.ViewCompoNames[ViewModel.DisplayName.CY_MODAL])})`;
          map[name] = { value: name, label, hidden: true };
          map[id] = { value: id, label };
        });
      } else {
        const iterContains = (contains: ViewModel.View['contains'] = []) => {
          contains.forEach(node => {
            if (!componentType || node.component === componentType) {
              const label = `${node.name} (${translate(TRANS.client.ViewCompoNames[node.component])})`;
              map[node.name] = { label, value: node.name, hidden: true };
              map[node.id] = { label, value: node.id };
            }
            iterContains(node.contains);
          });
        };
        iterContains(source.contains);
      }
    }

    const isFinalValue = props.value && typeof props.value === 'string' && !/^{{(.*)}}$/.test(props.value.trim());
    return {
      optionList: Object.values(map),
      warning: isFinalValue && !map[props.value]
    };
  }, [item, view, props.value, componentType, translate]);

  const description = warning && `WARNING: ${componentType ? TRANS.client.ViewCompoNames[componentType] : 'Component'} not found`;
  const label = props.schema.title || props.schema.label;

  return (
    <EvaluatorWrapper
      label={label}
      allowFormula={props.schema.metadata.allowFormula}
      value={props.value}
      disabled={props.disabled}
      required={props.required}
      inputList={props.inputList}
      onChange={(props as any).onChange}
      format={GeneralModel.JSONSchemaFormat.TEXT}
      info={props.schema.info}
      disabledType={props.disabledType || props.schema?.metadata?.disabledType}
    >
      {fixedProps => {
        const innerCommonComponentProps = {
          value: fixedProps.value,
          optionList: fixedProps.evaluatorOptionList,
          onChange: fixedProps.onChange
        };
        return <SelectDropdown {...props} label={label} options={optionList} description={description} {...innerCommonComponentProps} />;
      }}
    </EvaluatorWrapper>
  );
};
