import { COLOR, DROPDOWN_BASE_STYLES, GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  tooltipContainer: css`
    ${DROPDOWN_BASE_STYLES};
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: ${GAP.XXS};
    background-color: ${COLOR.BASE_BACKGROUND};
    padding: ${GAP.S};
  `,
  tooltipLabel: css`
    font-weight: 700;
  `
};
