import type { RangeSelection } from 'lexical';
import { $isAtNodeEnd } from '@lexical/selection';

export const getSelectedNode = (selection: RangeSelection) => {
  if (!selection) return;

  const anchor = selection.anchor;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }

  return $isAtNodeEnd(anchor) ? anchorNode : focusNode;
};
