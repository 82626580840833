import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { UIState } from './state';
import { getInitialState } from './state';

const uiSlice = createSlice({
  name: 'ui',
  initialState: getInitialState(),
  reducers: {
    setModalInteraction: (state, action: PayloadAction<UIState['modalInteraction']>) => {
      state.modalInteraction = action.payload;
    }
  }
});

export const { setModalInteraction } = uiSlice.actions;
export const uiReducer = uiSlice.reducer;
