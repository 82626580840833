import { useMemo } from 'react';

import type { GeneralModel } from '@cyferd/client-engine';
import { getDisabledStates, Translate, ViewModel } from '@cyferd/client-engine';
import * as RadixCheckbox from '@radix-ui/react-checkbox';

import { Icon } from '../Icon/Icon';
import { InputDescription } from '../InputDescription';
import { COLOR, FOREGROUND_COLOR, SECONDARY_COLOR } from '@constants';
import { styles } from './styles';
import type { IOptionMenu } from '../OptionMenu';
import { OptionMenu } from '../OptionMenu';
import { InfoForLabel } from '../InfoForLabel';
import { CTAType } from '../CTA';
import { InputErrorMesssage } from '../InputErrorMessage';

const getIndeterminateIconColor = (isReadonly: boolean, isGreyedOut: boolean, defaultColor: GeneralModel.Color.ThemeColor) => {
  if (isReadonly) return COLOR.NEUTRAL_1;
  if (isGreyedOut) return COLOR.NEUTRAL_1_5;
  return defaultColor;
};

const getCheckIconColor = (isReadonly: boolean, isGreyedOut: boolean, defaultColor: GeneralModel.Color.ThemeColor) => {
  if (isReadonly) return COLOR.NEUTRAL_1;
  if (isGreyedOut) return COLOR.NEUTRAL_1_5;
  return defaultColor;
};

const getBoxBackgroundColor = (value: boolean, isReadonly: boolean, valueColor: GeneralModel.Color.ThemeColor) => {
  if (isReadonly) return COLOR.TRANSPARENT;
  if (!!value) return valueColor;
  return undefined;
};

const getBoxBorderColor = (
  value: boolean,
  isIndeterminate: boolean,
  isReadonly: boolean,
  isGreyedOut: boolean,
  defaultColor: GeneralModel.Color.ThemeColor
) => {
  if (isReadonly && !value && !isIndeterminate) return COLOR.NEUTRAL_1;
  if (isGreyedOut || (isReadonly && (!!value || isIndeterminate))) return COLOR.TRANSPARENT;
  return defaultColor;
};

export interface CheckboxProps {
  value?: boolean;
  labelPosition?: 'right' | 'left';
  label?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  id?: string;
  name?: string;
  color?: GeneralModel.Color.ThemeColor;
  onChange?: (value: boolean) => void;
  description?: string;
  errorMessage?: string;
  testid?: string;
  required?: boolean;
  optionList?: IOptionMenu['optionList'];
  info?: string;
  disabledType?: GeneralModel.DisabledType;
}

export const Checkbox = ({
  labelPosition = 'right',
  disabled,
  label,
  name,
  value,
  id,
  onChange,
  color,
  autoFocus,
  testid = 'checkbox',
  description,
  required,
  errorMessage,
  optionList,
  info,
  disabledType
}: CheckboxProps) => {
  const { isReadonly, isGreyedOut } = getDisabledStates(disabled, disabledType);
  const isIndeterminate = value === undefined || value === null;
  const mainColor: GeneralModel.Color.ThemeColor = useMemo(() => (COLOR[color] ? color : 'BRAND_1'), [color]);
  const stateColor = useMemo(() => (!isGreyedOut ? COLOR[mainColor] : SECONDARY_COLOR[mainColor]), [isGreyedOut, mainColor]) as GeneralModel.Color.ThemeColor;

  const onInputChange = () => {
    onChange?.(!value);
  };

  const labelElement = useMemo(
    () =>
      !!label || required || !!description ? (
        <div data-testid={`${testid}-label`} css={[styles.label, labelPosition === 'left' && styles.labelLeft]}>
          <div data-testid={`${testid}-title`} css={[styles.text, styles.title]} data-selector="checkbox-label">
            <Translate>{label}</Translate> <InfoForLabel label={label} value={info} testId={testid} />
            {required ? '*' : ''}
          </div>
          <InputDescription description={description} showPadding={false} />
        </div>
      ) : null,
    [description, info, label, labelPosition, testid, required]
  );

  return (
    <div>
      <div css={styles.container} data-selector="checkbox-container">
        <label css={[styles.checkboxElement, disabled && styles.checkboxElementDisabled]} data-testid={`${testid}-container`} htmlFor={id}>
          {labelPosition === 'left' && labelElement}
          <RadixCheckbox.Root
            css={[styles.checkbox, isGreyedOut && styles.greyedOutCheckbox]}
            style={{
              backgroundColor: getBoxBackgroundColor(value, isReadonly, stateColor),
              borderColor: getBoxBorderColor(value, isIndeterminate, isReadonly, isGreyedOut, stateColor)
            }}
            checked={value}
            autoFocus={autoFocus}
            onCheckedChange={onInputChange}
            disabled={disabled}
            aria-errormessage={errorMessage ? `${id}-error` : undefined}
            aria-invalid={errorMessage ? 'true' : 'false'}
            name={name}
            id={id}
            data-testid={testid}
            data-focusable={!disabled}
          >
            {isIndeterminate && (
              <div data-testid={`${testid}-indeterminate`} css={styles.iconIndeterminate}>
                <Icon name="remove" fill={getIndeterminateIconColor(isReadonly, isGreyedOut, stateColor)} />
              </div>
            )}
            <RadixCheckbox.Indicator css={[styles.checkIcon, isReadonly && styles.readonlyCheckIcon]}>
              {value === true && (
                <Icon name="check" fill={getCheckIconColor(isReadonly, isGreyedOut, FOREGROUND_COLOR[mainColor] as GeneralModel.Color.ThemeColor)} />
              )}
            </RadixCheckbox.Indicator>
          </RadixCheckbox.Root>
          {labelPosition === 'right' && labelElement}
        </label>
        <OptionMenu defaultBtnType={CTAType.LINK} optionList={optionList} defaultBtnSize={ViewModel.CTASize.MEDIUM} />
      </div>
      <InputErrorMesssage id={id} message={errorMessage} testid={testid} showLine={true} />
    </div>
  );
};

Checkbox.displayName = 'Checkbox';
