import type { ComponentProps } from 'react';
import { Children, memo, useCallback, useId, useMemo, useState } from 'react';

import { Translate, ViewModel, getParsedActionChildren, isDeepEqual } from '@cyferd/client-engine';

import { Collapsible } from '@components/elements/Collapsible';
import { TabList, VerticalTabList } from '@components/elements/TabList';
import { getColorValue } from '@utils/getColorValue';
import { useGetElementSize } from '@utils/useGetElementSize';
import { BBContainer } from '../BBContainer';
import { CTAType } from '../CTA';
import { IconImage } from '../Icon/renderIcon';
import type { IOptionMenu } from '../OptionMenu';
import { OptionMenu } from '../OptionMenu';
import { containerType, getRuntimeType, styles } from './styles';
import { useGetItemHeight } from '@utils';

export type LayoutProps = {
  maxWidth?: number;
  ResizeObserverClass?: typeof ResizeObserver;
  testid?: string;
  actionListChildren?: ViewModel.ViewHeaderProps['actionListChildren'];
  showHeaderDivision?: boolean;
  image?: string;
  menuConfig?: Pick<
    IOptionMenu,
    'defaultBtnType' | 'defaultBtnSize' | 'defaultBtnIcon' | 'defaultBtnTestid' | 'defaultTooltip' | 'defaultBtnColor' | 'defaultDisabled' | 'defaultOutlined'
  >;
} & ViewModel.CyLayoutProps;

const justifyContentMap = {
  [ViewModel.LayoutVerticalAlignment.CENTER]: 'center',
  [ViewModel.LayoutVerticalAlignment.TOP]: 'flex-start',
  [ViewModel.LayoutVerticalAlignment.BOTTOM]: 'flex-end',
  [ViewModel.LayoutVerticalAlignment.BETWEEN]: 'space-between',
  [ViewModel.LayoutVerticalAlignment.EVENLY]: 'space-evenly'
};

export const Layout = memo(
  ({
    id,
    type,
    title,
    subtitle,
    startsCollapsed,
    isCollapsible,
    canToggleFullscreen,
    color,
    children,
    maxWidth = 280,
    border,
    testid = 'layout',
    tabList,
    activeTab,
    onChangeTab,
    itemHeight,
    itemMaxHeight,
    itemMinHeight,
    verticalAlignment,
    ResizeObserverClass,
    effectChildren,
    framed,
    actionListChildren,
    showHeaderDivision,
    image,
    fitToPage,
    tabColor,
    menuConfig
  }: LayoutProps) => {
    const [fsIndex, setFsIndex] = useState<number>(null);
    const { ref, width, top } = useGetElementSize(ResizeObserverClass);
    const internalId = useId();
    const headerSelector = `${internalId}-header`;

    const [childIdList, setChildIdList] = useState<number[]>([]);
    const childCount = childIdList.length;
    const isFullScreen = typeof fsIndex === 'number';

    const runtimeType = useMemo(() => getRuntimeType({ width, maxWidth, childCount, isFullScreen, type }), [width, maxWidth, childCount, isFullScreen, type]);

    const isHorizontalTab = runtimeType === ViewModel.LayoutType.TAB;
    const isVerticalTab = runtimeType === ViewModel.LayoutType.VERTICAL_TAB;
    const isTabLayout = isHorizontalTab || isVerticalTab;
    const activeTabIndex = isTabLayout && (tabList || []).findIndex(t => !t.hidden && t.title === activeTab);

    const columnStyle = useMemo(() => ({ backgroundColor: getColorValue(color) }), [color]);

    const height = useGetItemHeight(itemHeight, top);
    const maxHeight = useGetItemHeight(itemMaxHeight, top);
    const minHeight = useGetItemHeight(itemMinHeight, top);
    const justifyContent = justifyContentMap[verticalAlignment];

    const heightStyle = {
      ...(height ? { height } : {}),
      ...(maxHeight ? { maxHeight } : {}),
      ...(minHeight ? { minHeight } : {}),
      ...(justifyContent ? { justifyContent } : {})
    };

    const parsedActionChildren = useMemo(() => getParsedActionChildren(actionListChildren), [actionListChildren]);

    const renderHeader: ComponentProps<typeof Collapsible>['renderHeader'] = useCallback(
      (onToggle, toggleButton, isOpen) => {
        const hasContent = !!title?.trim() || !!subtitle?.trim() || !!parsedActionChildren?.length;
        return (
          <div css={[styles.headerContainer, !!showHeaderDivision && hasContent && styles.headerDivision, isOpen && hasContent && styles.openHeaderContainer]}>
            <div css={styles.headerContent}>
              <IconImage icon={image} title={title} iconProps={{ size: '32px' }} imageProps={{ size: '28px' }} testid={`${testid}-icon`} />
              <div data-selector={headerSelector} role="button" onClick={isCollapsible || startsCollapsed ? onToggle : null}>
                <div data-testid="effects">{effectChildren}</div>
                {!!(title || isCollapsible || startsCollapsed) && (
                  <div css={styles.header}>
                    {!!(isCollapsible || startsCollapsed) && toggleButton}
                    <h3 css={styles.title}>
                      <Translate>{title}</Translate>
                    </h3>
                  </div>
                )}
                {!!subtitle?.trim() && (
                  <h4 css={styles.subtitle}>
                    <Translate>{subtitle}</Translate>
                  </h4>
                )}
              </div>
            </div>
            {!!parsedActionChildren?.length && (
              <div>
                <OptionMenu
                  defaultBtnType={ViewModel.CTAType.LINK}
                  {...menuConfig}
                  optionList={parsedActionChildren.map(actionItem => ({
                    label: actionItem.label,
                    tooltip: actionItem.helperText,
                    image: actionItem.icon,
                    status: (actionItem as any).status,
                    size: (actionItem as any).size || ViewModel.CTASize.SMALL,
                    disabled: !!actionItem.disabled,
                    onClick: actionItem.onClick,
                    type: actionItem.type ?? CTAType.ACTION_TERTIARY,
                    important: actionItem.important,
                    color: actionItem.color
                  }))}
                />
              </div>
            )}
          </div>
        );
      },
      [title, subtitle, parsedActionChildren, showHeaderDivision, image, testid, headerSelector, isCollapsible, startsCollapsed, effectChildren, menuConfig]
    );

    const onChildCountChange = ($container: HTMLDivElement) => {
      const list = Array.from($container?.querySelectorAll(`[data-layout-id="${internalId}"]`) || [])
        .map(($child, index) => !!$child.innerHTML && index)
        .filter(id => typeof id === 'number');

      if (!isDeepEqual(list, childIdList)) setChildIdList(list);
    };

    return (
      <BBContainer framed={framed}>
        <Collapsible open={!startsCollapsed} renderHeader={renderHeader} ref={ref}>
          <div css={[isVerticalTab && styles.verticalTabContainer]}>
            {isHorizontalTab && <TabList tabColor={tabColor} tabList={tabList} activeTab={activeTab} onChangeTab={onChangeTab} />}
            {isVerticalTab && <VerticalTabList tabColor={tabColor} tabList={tabList} activeTab={activeTab} onChangeTab={onChangeTab} />}
            <BBContainer fitToPage={fitToPage} cssOverload={styles.bbContainer}>
              <div id={id} ref={onChildCountChange} css={[styles.container, containerType[runtimeType]]} data-testid={testid}>
                {Children.toArray(children).map((child, index) => {
                  if (isTabLayout && index !== activeTabIndex) return null;
                  const isBlank = (child as any)?.props?.node?.component === ViewModel.DisplayName.CY_BLANK;

                  return (
                    <div
                      data-testid={`${testid}-col-${index + 1}`}
                      key={index}
                      css={[
                        styles.column,
                        isFullScreen && fsIndex === index && styles.fsColumn,
                        !!border && styles.borderContainer,
                        !!color && styles.colorContainer,
                        !isTabLayout && !childIdList.includes(index) && styles.hidden,
                        isBlank && styles.blank
                      ]}
                      style={columnStyle}
                    >
                      {!!canToggleFullscreen && (
                        <div data-fs-toggle={true} css={styles.fsToggle}>
                          <div data-testid={`${testid}-toggle-fs`} onClick={() => setFsIndex(isFullScreen ? null : index)} role="button">
                            <div css={[styles.fsButton, isFullScreen ? styles.expand : styles.collapse]} />
                          </div>
                        </div>
                      )}
                      <div data-layout-id={internalId} css={styles.content} style={isBlank ? undefined : heightStyle}>
                        {child}
                      </div>
                    </div>
                  );
                })}
              </div>
            </BBContainer>
          </div>
        </Collapsible>
      </BBContainer>
    );
  }
);

Layout.displayName = 'Layout';
