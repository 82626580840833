import { css, cx } from '@emotion/css';
import { HEADINGS_FONT_SIZE } from './constants';
import { COLOR, FONT_SIZE, GAP, RADIUS } from '@constants';

const styles = {
  text: {
    p: css`
      font-weight: 400;
      padding: 2px;
    `,
    bold: css`
      font-weight: 600 !important;
    `,
    italic: css`
      font-style: italic;
    `,
    quote: css`
      margin: 1em 0;
      padding-left: 1em;
      border-left: 3px solid #ccc;
      font-style: italic;
      color: #666;
      text-align: left;
      font-size: ${FONT_SIZE.S};
    `,
    textStrikethrough: css`
      text-decoration: line-through;
    `,
    textUnderline: css`
      text-decoration: underline;
    `,
    textUnderlineStrikethrough: css`
      text-decoration: underline line-through;
    `
  },
  list: {
    listUl: css`
      margin: 0;
      padding: 0;
      list-style-position: inside;
      list-style-type: disc;

      & ul {
        margin-left: 20px;
        list-style-type: circle;
      }

      & ul ul {
        margin-left: 40px;
        list-style-type: square;
      }
    `,
    listOl: css`
      margin: 0;
      padding: 0;
      list-style-position: inside;
      list-style-type: decimal;

      & ol {
        margin-left: 20px;
        list-style-type: lower-latin;
      }

      & ol ol {
        margin-left: 40px;
        list-style-type: lower-roman;
      }
    `,
    listItem: css`
      margin: 0.5em 0;
      color: ${COLOR.NEUTRAL_1};
      font-weight: 400;
    `,
    nestedListItem: css`
      list-style-type: none;

      &:before {
        visibility: hidden;
      }

      &:after {
        visibility: hidden;
      }
    `,
    listItemCheckListBase: css`
      position: relative;
      margin-left: 8px;
      margin-right: 8px;
      padding-left: 24px;
      padding-right: 24px;
      list-style-type: none;
      outline: none;

      &:before {
        content: '';
        width: 14px;
        height: 14px;
        top: 2px;
        left: 0;
        cursor: pointer;
        display: block;
        background-size: cover;
        position: absolute;
      }
    `,
    listItemChecked: css`
      text-decoration: line-through;

      &:before {
        border: 2px solid ${COLOR.BRAND_1};
        border-radius: 2px;
        background-color: ${COLOR.BRAND_1};
      }

      &:after {
        content: '';
        cursor: pointer;
        border-color: #fff;
        border-style: solid;
        position: absolute;
        display: block;
        top: 6px;
        width: 4px;
        left: 5px;
        right: 7px;
        height: 6px;
        transform: rotate(45deg);
        border-width: 0 1px 1px 0;
      }
    `,
    listItemUnchecked: css`
      &:before {
        border: 2px solid ${COLOR.BRAND_1};
        border-radius: 2px;
      }
    `
  },
  h1: css`
    font-size: ${HEADINGS_FONT_SIZE.h1};
    padding-top: 10px;
  `,
  h2: css`
    font-size: ${HEADINGS_FONT_SIZE.h2};
    padding-top: 10px;
  `,
  h3: css`
    font-size: ${HEADINGS_FONT_SIZE.h3};
    padding-top: 10px;
  `,
  h4: css`
    font-size: ${HEADINGS_FONT_SIZE.h4};
    padding-top: 10px;
  `,
  hr: css`
    padding: 2px 2px;
    border: none;
    margin: 1em 0;
    cursor: pointer;

    &:after {
      content: '';
      display: block;
      height: 2px;
      background-color: var(--BASE_FOREGROUND);
      line-height: 2px;
    }
  `,
  link: css`
    color: ${COLOR.BRAND_1};
    text-decoration: underline;
    cursor: pointer;
    font-weight: 400;
  `,
  table: css`
    border-collapse: collapse;
    border-spacing: 0;
    overflow-y: auto;
    overflow-x: auto;
    margin-bottom: 30px;
    display: block;
    table-layout: auto;
  `,
  tableCell: css`
    border: 1px solid ${COLOR.NEUTRAL_3};
    vertical-align: top;
    text-align: start;
    padding: ${GAP.M} ${GAP.L} ${GAP.M} ${GAP.S};
    position: relative;
    outline: none;
    width: auto !important;
    min-width: 100px;
    max-width: 400px;
    background-color: ${COLOR.BASE_BACKGROUND};
  `,
  tableHeader: css`
    background-color: ${COLOR.BASE_BACKGROUND} !important;
    text-align: start;
    min-width: 100px;
    max-width: 400px;
  `,
  code: css`
    background-color: ${COLOR.NEUTRAL_4};
    color: ${COLOR.BASE_FOREGROUND};
    display: block;
    padding: ${GAP.XS};
    padding-left: ${GAP.L};
    border-radius: ${RADIUS.S};
    line-height: 1.53;
    margin: 0;
    margin-top: 8px;
    margin-bottom: 8px;
    overflow-x: auto;
    position: relative;
    tab-size: 2;
    border-left: 3px solid ${COLOR.BRAND_1};
    white-space: pre-wrap;
    font-size: ${FONT_SIZE.S};
    text-align: left;

    // TODO: implement lines for code block
    //counter-reset: line;

    // span {
    //   counter-increment: line; /* Incrementing line counter */
    //   position: relative; /* Ensure absolute positioning is relative to span */
    //   //margin-left: ;
    // }
    //
    // span:before {
    //   content: counter(line); /* Displaying the current line number */
    //   //position: absolute;
    //   //background-color: #eee;
    //   left: 0;
    //   top: 0;
    //   border-right: 1px solid #ccc;
    //   padding: ${GAP.XS};
    //   color: #777;
    //   white-space: pre-wrap;
    //   text-align: right;
    //   min-width: 25px; /* Minimum width for line numbers */
    //   margin-right: 8px; /* Space between line numbers and code */
    // }
  `
};

export const editorTheme = {
  paragraph: cx(styles.text.p),
  quote: cx(styles.text.quote),
  heading: {
    h1: cx(styles.h1),
    h2: cx(styles.h2),
    h3: cx(styles.h3),
    h4: cx(styles.h4)
  },
  text: {
    bold: cx(styles.text.bold),
    italic: cx(styles.text.italic),
    strikethrough: cx(styles.text.textStrikethrough),
    underline: cx(styles.text.textUnderline),
    underlineStrikethrough: cx(styles.text.textUnderlineStrikethrough)
  },
  list: {
    ul: cx(styles.list.listUl),
    ol: cx(styles.list.listOl),
    listitemChecked: cx(styles.list.listItemCheckListBase, styles.list.listItemChecked),
    listitemUnchecked: cx(styles.list.listItemCheckListBase, styles.list.listItemUnchecked),
    listItem: cx(styles.list.listItem),
    nested: {
      listitem: cx(styles.list.nestedListItem)
    }
  },
  code: cx(styles.code),
  hr: cx(styles.hr),
  link: cx(styles.link),
  table: cx(styles.table),
  tableCell: cx(styles.tableCell),
  tableCellHeader: cx(styles.tableHeader)
};
